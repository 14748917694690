import React, { useState, useEffect } from 'react';
import { Box, Heading, Text, VStack, Image } from '@chakra-ui/react';
import { useInView } from 'react-intersection-observer';

// Function to convert image to base64
const getBase64 = (url, callback) => {
  const xhr = new XMLHttpRequest();
  xhr.onload = function () {
    const reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
};

const WelcomeBanner = () => {
  const headingFontSize = { base: '1.2em', md: '1.8em', lg: '3.5em' };
  const imageSize = { base: '6em', md: '6em', lg: '5.5em' };
  const paragraphFontSize = { base: '0.8em', md: '1em', lg: '1.5em' };
  const paragraphSize = { base: '0.7em', md: '0.8em', lg: '1.2em' };
  
  const [imageSrcs, setImageSrcs] = useState({ logo: '', main: '' });
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const [inView] = useInView({
    triggerOnce: true,
    rootMargin: '100px 0px',
    threshold: 0.5,
  });

  useEffect(() => {
    const loadImages = () => {
      const logoUrl = '/images/logo-no-background.png';
      const mainImageUrl = '/images/20230919172927.png';

      const logoCached = localStorage.getItem('logo');
      const mainCached = localStorage.getItem('mainImage');

      if (logoCached && mainCached) {
        setImageSrcs({ logo: logoCached, main: mainCached });
        setImagesLoaded(true);
      } else {
        getBase64(logoUrl, (logoBase64) => {
          localStorage.setItem('logo', logoBase64);
          setImageSrcs((prev) => ({ ...prev, logo: logoBase64 }));
        });
        getBase64(mainImageUrl, (mainBase64) => {
          localStorage.setItem('mainImage', mainBase64);
          setImageSrcs((prev) => ({ ...prev, main: mainBase64 }));
        });
        setImagesLoaded(true);
      }
    };

    loadImages();
  }, [inView]);

  return (
    <Box
      bg="black"
      w="100%"
      h={{ base: '60vh', md: '110vh', lg: '110vh' }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="relative"
      overflow="hidden"
      color="white"
    >
      <VStack
        spacing={4}
        alignItems="center"
        zIndex="1"
        p={4}
        background="rgba(0, 0, 0, 0.7)"
        width="100%"
        height="100%"
        position="absolute"
        top="0"
        left="0"
      >
        <Heading
          fontFamily="Hammersmith One, sans-serif"
          marginTop="20px"
          color="#dcdcdc"
          fontSize={headingFontSize}
          display="flex"
          alignItems="center"
        >
          Welcome to the{' '}
          {imagesLoaded && (
            <Image
              src={imageSrcs.logo}
              alt="Maharaj567"
              w={imageSize}
              h="auto"
              loading="lazy"
              marginLeft="10px"
            />
          )}
        </Heading>
        <Text
          fontSize={paragraphFontSize}
          fontFamily="Hammersmith One, sans-serif"
          fontWeight="medium"
          color="#d2122e"
          textAlign="center"
          maxWidth="90%"
        >
          We Believe In Trust, Transparency, and Honesty
        </Text>

        {imagesLoaded && (
          <Image
            src={imageSrcs.main}
            maxW="100%"
            h="auto"
            loading="eager"
            placeholder="empty"
          />
        )}
      </VStack>
    </Box>
  );
};

export default WelcomeBanner;
