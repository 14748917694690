import React, { createContext, useState, useEffect, useContext } from 'react';
import { CircularProgress ,Center} from '@chakra-ui/react';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initializeAuth = async () => {
      const loggedInUser = localStorage.getItem('user');
      const token = localStorage.getItem('token');

      if (loggedInUser) {
        setUser(JSON.parse(loggedInUser));
      }

      if (token) {
        await verifyToken(token);
      } else {
        setLoading(false); // Token not found, stop loading
      }

      setLoading(false); // Stop loading once checks are done
    };

    initializeAuth();

    const handleStorageChange = (event) => {
      if (event.key === 'user') {
        const updatedUser = localStorage.getItem('user');
        setUser(updatedUser ? JSON.parse(updatedUser) : null);
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const verifyToken = async (token) => {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Get the current time in seconds

      // Check if the token is expired
      if (decodedToken.exp < currentTime) {
        console.error('Token expired');
        logout();
        return;
      }

      // If not expired, verify the token with the backend
      const response = await axios.get('https://mainproject-ao3x.onrender.com/protected', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const { name, username } = decodedToken;
        setUser({ name, username });
        localStorage.setItem('user', JSON.stringify({ name, username }));
        localStorage.setItem('authenticated', 'true');
      } else {
        throw new Error('Token invalid or expired');
      }
    } catch (error) {
      console.error('Error verifying token:', error);
      logout();
    }
  };

  const login = (userData) => {
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
    localStorage.setItem('authenticated', 'true');
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('authenticated');
    localStorage.setItem('logout', Date.now());
    window.location.href = '/login'; // Redirect to login page after logout
  };

  const register = async (username, password) => {
    try {
      const response = await fetch("https://mainproject-ao3x.onrender.com/api/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });
      const data = await response.json();
      if (data.status === 'error') {
        throw new Error(data.error || "Error registering user");
      }
      const token = data.token;
      localStorage.setItem("token", token);
      const decodedToken = jwtDecode(token);
      const { name, username: userName } = decodedToken;
      localStorage.setItem("authenticated", "true");
      localStorage.setItem("user", JSON.stringify({ name, username: userName }));
      setUser({ name, username: userName });
      return data;
    } catch (error) {
      console.error("Error registering user:", error);
      throw new Error("Error registering user");
    }
  };

  if (loading) {
    return (
      <Center minH={'100vh'}>
        <CircularProgress isIndeterminate color="green.300" />
      </Center>
    );
  }

  return (
    <AuthContext.Provider value={{ user, login, logout, register }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
