// CardContext.js
import React, { createContext, useContext, useState } from 'react';

const CardContext = createContext();

export const useCardContext = () => {
  return useContext(CardContext);
};

export const CardProvider = ({ children }) => {
  const [cardData, setCardData] = useState([]);

  const updateCard = (cardId, updatedData) => {
    setCardData((prevData) =>
      prevData.map((card) =>
        card._id === cardId ? { ...card, ...updatedData } : card
      )
    );
  };

  return (
    <CardContext.Provider value={{ cardData, updateCard }}>
      {children}
    </CardContext.Provider>
  );
};
