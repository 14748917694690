import React from 'react';
import { Box, Heading, Text, ChakraProvider } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const MotionBox = motion(Box);

const ComingSoon = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 1 } },
  };

  const textVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0, transition: { duration: 1, delay: 0.5 } },
  };

  return (
    <ChakraProvider>
      <MotionBox
        height="90vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
        backgroundColor="gray.100"
        px={[4, 8, 12]} // Responsive padding
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <MotionBox textAlign="center" maxW="xl" variants={textVariants}>
          <Heading fontSize={['2xl', '3xl', '4xl']} color="red.500">
            Coming Soon....
          </Heading>
          <Text fontSize={['md', 'lg', 'xl']} color="gray.600" mt={4}>
           Thank You for visiting our app is coming soon so please stay Tuned...
          </Text>
          <MotionBox
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            mt={8}
            p={4}
            bgColor="red.500"
            color="white"
            borderRadius="md"
            cursor="pointer"
            fontSize="lg"
            fontWeight="bold"
          >
       MAHARAJ  567
          </MotionBox>
        </MotionBox>
      </MotionBox>
    </ChakraProvider>
  );
};

export default ComingSoon;
