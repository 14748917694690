import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Heading, SimpleGrid, Text, Icon, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Button } from '@chakra-ui/react';
import { FaDiceOne, FaDiceTwo ,FaDiceThree, FaDice } from 'react-icons/fa';
import { useHeading } from './ContextProvider/HeadingContext';
import gameTimingData from './gameTimingData.json';
import { GiCardAceHearts } from "react-icons/gi";
import { CgSandClock } from "react-icons/cg";

const GameScreen = () => {
  const navigate = useNavigate();
  const { gameName } = useParams(); // gameName from URL param
  const { setHeading } = useHeading();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [canPlay, setCanPlay] = useState(true);

  useEffect(() => {
    const heading = gameName.charAt(0).toUpperCase() + gameName.slice(1);
    setHeading(heading);
  }, [gameName, setHeading]);

  useEffect(() => {
    const checkMarketStatus = () => {
      const now = new Date();
      const currentTime = now.getHours() * 60 + now.getMinutes(); // Current time in minutes since midnight
      const gameData = gameTimingData?.find(game => game.title === gameName);
      if (!gameData) {
        setCanPlay(true); // Allow play if no timing data is found
        return;
      }

      const openBidLastTime = gameData.openBidLastTime || '00:00 AM';
      const [hours, minutesWithPeriod] = openBidLastTime.split(':');
      const [minutes, period] = minutesWithPeriod.split(' ');

      let parsedHours = parseInt(hours, 10);
      const parsedMinutes = parseInt(minutes, 10);
      if (period === 'PM' && parsedHours !== 12) {
        parsedHours += 12;
      } else if (period === 'AM' && parsedHours === 12) {
        parsedHours = 0; // Midnight case
      }

      const openBidLast = parsedHours * 60 + parsedMinutes;
      setCanPlay(currentTime < openBidLast); // Set `canPlay` state based on time
    };

    checkMarketStatus();
    const timer = setInterval(checkMarketStatus, 60000); // Check every minute
    return () => clearInterval(timer);
  }, [gameName, gameTimingData]);

  const handleIconPress = (game) => {
    if ((game === 'jodidigit' || game === 'jodidigitbulk') && !canPlay) {
      // Open the modal if 'jodidigit' or 'jodidigitbulk' is clicked and playing is restricted
      onOpen();
    } else {
      // Navigate if it's any other game or if play is allowed
      navigate(`/gamescreen/${gameName}/${game}`);
    }
  };
  
  const gamblingIcons = [
    { name: GiCardAceHearts, label: 'Single Digit', game: 'singledigit', color: '#3498db' },
    { name: GiCardAceHearts, label: 'Single Digit Bulk', game: 'singledigitbulk', color: '#e74c3c' },
    { name: FaDice, label: 'Jodi', game: 'jodidigit', color: '#2ecc71' },
    { name: FaDice, label: 'Jodi Bulk', game: 'jodidigitbulk', color: '#9b59b6' },
    { name: FaDiceOne, label: 'Single Pana', game: 'singlepana', color: '#f1c40f' },
    { name: FaDiceOne, label: 'Single Pana Bulk', game: 'singlepanabulk', color: '#f3c10f' }, 
    { name: FaDiceTwo, label: 'Double Pana', game: 'doublepana', color: '#8e44ad' },
    { name: FaDiceTwo, label: 'Double Pana Bulk', game: 'doublepanabulk', color: '#355F2E' }, 
    { name: FaDiceThree, label: 'Triple Pana', game: 'triplepana', color: '#16a085' },
    { name: CgSandClock, label: 'Odd Even', game: 'oddeven', color: '#e74c3c' },
  ];

  return (
    <Box
      padding={{ base: '15px', md: '20px' }}
      backgroundColor="#1e2a47"
      backgroundImage="linear-gradient(135deg, #1e2a47 30%, #4a69bd 70%)"
      minHeight="100vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Heading
        as="h1"
        size="xl"
        mb="20px"
        textAlign="center"
        fontWeight="700"
        bgGradient="linear(to-r, #ff0080, #7928ca)"
        bgClip="text"
        fontSize={{ base: 'xl', md: '4xl', lg: '5xl' }}
        letterSpacing="wide"
        textTransform="uppercase"
        boxShadow="0 4px 15px rgba(0, 0, 0, 0.2)"
        padding="10px"
        borderRadius="10px"
      >
        {gameName.charAt(0).toUpperCase() + gameName.slice(1)}
      </Heading>

      <SimpleGrid columns={{ base: 2, sm: 2, md: 2, lg: 3 }} spacing={{ base: 4, md: 6 }} maxWidth="1200px" width="100%">
        {gamblingIcons.map((icon) => {
          const IconComponent = icon.name;
          return (
            <Box
              key={icon.label}
              borderRadius="15px"
              border="2px solid transparent"
              backgroundClip="padding-box"
              background={`linear-gradient(white, white) padding-box, linear-gradient(to right, ${icon.color}, #8e44ad) border-box`}
              boxShadow="0 4px 12px rgba(0, 0, 0, 0.1)"
              padding={{ base: '10px', md: '20px' }}
              textAlign="center"
              cursor="pointer"
              transition="transform 0.3s, box-shadow 0.3s, scale 0.2s"
              onClick={() => handleIconPress(icon.game)}
              _hover={{
                transform: 'scale(1.08)',
                boxShadow: '0 10px 25px rgba(0, 0, 0, 0.25)',
                border: '2px solid rgba(255, 255, 255, 0.8)',
                transition: 'transform 0.2s ease-out',
              }}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                width={{ base: '60px', md: '80px' }}
                height={{ base: '60px', md: '80px' }}
                marginBottom={{ base: '5px', md: '10px' }}
                background={`linear-gradient(135deg, ${icon.color}, #8e44ad)`}
                transition="background 0.3s"
                boxShadow="0 4px 8px rgba(0, 0, 0, 0.15)"
                _hover={{
                  transform: 'scale(1.15)',
                  transition: 'transform 0.3s',
                }}
              >
                <Icon as={IconComponent} boxSize={{ base: 8, md: 10 }} color="#fff" />
              </Box>
              <Text
                fontSize={{ base: 'sm', md: 'lg' }}
                fontWeight="600"
                textShadow="2px 2px 4px rgba(0, 0, 0, 0.3)"
                transition="color 0.3s ease"
                _hover={{ color: `${icon.color}` }}
              >
                {icon.label}
              </Text>
            </Box>
          );
        })}
      </SimpleGrid>

      {/* Modal for bid closed */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent textAlign="center" maxWidth="300px" bg="white" color="grey.800">
          <ModalHeader>Market Closed</ModalHeader>
          <ModalCloseButton color="red" />
          <ModalBody>
            <Text color={'red.500'}>Sorry, the market for {gameName} Jodi is currently closed.</Text>
            <Button mt={4} colorScheme="red" onClick={onClose}>
              Close
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default GameScreen;
