import React, { useState, useEffect } from 'react';
import { IconButton, Badge, Box } from '@chakra-ui/react';
import { FaBell } from 'react-icons/fa';
import axios from 'axios';
import { Link } from 'react-router-dom';

const NotificationBell = () => {
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    const fetchUnreadCount = async () => {
      try {
        const response = await axios.get('https://mainproject-ao3x.onrender.com/notifications/unread');
        setUnreadCount(response.data.unreadCount);
      } catch (error) {
        console.error('Error fetching unread count:', error);
      }
    };

    const interval = setInterval(fetchUnreadCount, 5000);
    fetchUnreadCount(); // Initial fetch on component mount

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  const handleNotificationClick = () => {
    if (unreadCount > 0) {
      setUnreadCount(0); // Clear unread count on click
    }
  };

  return (
    <Link to="/notification">
      <IconButton
        onClick={handleNotificationClick}
        icon={
          <Box position="relative">
            <FaBell size={17} color="#d2122e" /> {/* Icon color set to white */}
            {unreadCount > 0 && (
              <Badge
                position="absolute"
                top="0"
                right="0"
                colorScheme="red"
                borderRadius="full"
                width={{ base: '16px', md: '18px', lg: '18px' }}
                height={{ base: '16px', md: '18px', lg: '18px' }}
                display="flex"
                alignItems="center"
                justifyContent="center"
                fontSize={{ base: '0.6em', md: '0.75em', lg: '0.85em' }}
                boxShadow="md"
                transition="all 0.3s"
                transform="translate(30%, -30%)" /* Adjust badge position */
              >
                {unreadCount}
              </Badge>
            )}
          </Box>
        }
        variant="unstyled" // Removes the border and background
        size="lg"
        aria-label="Notification"
        display="flex"
        alignItems="center"
        justifyContent="center"
      />
    </Link>
  );
};

export default NotificationBell;
