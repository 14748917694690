import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  VStack,
  Center,
  Badge,
  Flex,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTable } from "@fortawesome/free-solid-svg-icons";
import './Style/Card.css'; // Import the CSS file

function Card({ item, downloadLink }) {
  const [isHovered, setIsHovered] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [previousData, setPreviousData] = useState(null);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    // Check if the data has changed
    if (previousData && (item.h8 !== previousData.h8 || item.h9 !== previousData.h9)) {
      setIsNew(true);
    } else {
      setIsNew(false);
    }
    // Update previous data
    setPreviousData(item);
  }, [item]);

  const badgeData = [
    { text: 'Jodi ', path: `/records/table/${item.h8.toUpperCase()}` },
    { text: 'Panel ', path: `/pannel/table/${item.h8.toUpperCase()}` },
  ];

  return (
    <Center
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      transition="all 0.3s"
      borderRadius="505px"
      p={3}
      width="100%"
    >
      <Box
        bg="linear-gradient(176deg, rgba(0,0,0,1) 0%, rgba(227,0,27,1) 29%, rgba(0,0,0,1) 69%, rgba(0,0,0,1) 83%, rgba(255,0,0,1) 93%, rgba(227,0,27,1) 93%, rgba(225,0,30,1) 100%);"
        p={8}
        boxShadow={'rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;'}
        mx={2}
        minW={['250px', '285px', '300px']}
        minH={['250px', '285px', '300px']}
        css={{
          '@media screen and (min-width: 1024px) and (max-width: 1024px)': {
            minWidth: '290px',
            minHeight: '290px'
          },
          '@media screen and (min-width: 320px) and (max-width: 320px)': {
            minWidth: '280px',
            minHeight: '285px'
          },
        }}
        textAlign="center"
        display="flex"
        className={'gradient-border'}
        borderRadius="505px"
        flexDirection="column"
        justifyContent="space-between"
        position="relative"
      >
        <Flex justifyContent="center" alignItems="center" mb={2}>
          <Badge
            colorScheme='black'
            fontSize='20px'
            fontWeight={'bold'}
            borderRadius='10px'
            style={{
              backgroundImage: 'linear-gradient(90deg, #32CD32, #FFD700)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              animation: 'blinking 1.5s infinite',
            }}
          >
            {item.badge}
          </Badge>
          {/* Conditionally render the 'New' badge */}
          {isNew && (
            <Badge
              colorScheme="red"
              fontSize="sm"
              fontWeight="bold"
              ml={2}
              bg="red.400"
              color="white"
              borderRadius="10px"
              px={2}
              py={1}
            >
              New
            </Badge>
          )}
        </Flex>
        <Flex alignItems="center">
          {badgeData.map((badge, index) => (
            <Box key={index}>
              <Link to={badge.path}>
                <Badge
                  variant="subtle"
                  colorScheme="teal"
                  color={'black'}
                  bg={'blue.100'}
                  fontWeight={'extrabold'}
                  borderRadius={'80px'}
                  textTransform={'capitalize'}
                  fontSize="lg"
                  marginLeft={'20px'}
                  marginRight={'20px'}
                >
                  <FontAwesomeIcon icon={faTable} color={'black'} size='sm' fade />
                  {badge.text}
                </Badge>
              </Link>
            </Box>
          ))}
        </Flex>

        <VStack spacing={2} align="center">
          <Text fontSize={{ base: "2xl", md: '3xl', lg: "3xl" }} className={'gradient-text'} fontWeight={'bold'} color="blue.100" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
            {item.h8}
          </Text>
          <Text
            fontSize="3xl"
            fontWeight={'bold'}
            color={isHovered ? 'teal.500' : "blue.100"}
            textShadow={isHovered && '2px 2px 4px rgba(0, 0, 0, 0.5)'}
          >
            {item.h8 ? item.h9 : '***-**-***'}
          </Text>
        </VStack>
      </Box>
    </Center>
  );
}

export default Card;
