import React from 'react';
import { Box, Heading, Text, Stack } from '@chakra-ui/react';

const AboutUs = () => {
  return (
    <Box p={4} mx="auto" maxW={['95%', '90%', '80%', '90%']}>
      <Box
        p={6}
        bgGradient="linear(to-r, #d2122e, black)"
        boxShadow="md"
        borderRadius="md"
        mb={4}
      >
        <Heading as="h2" fontSize={['md', 'md', '2xl', '3xl']} mb={4} color="white">
          About Us
        </Heading>
        <Text fontSize={['sm', 'md', 'lg', 'xl']} color="white">

          Maharaj567  is operated by Techstra International, a company incorporated under the laws of United Kingdom and regulated by the UK authority as the regulatory body responsible holding a (The Gambling Commission under licences 574, 88965 & 11461)."We are the most reliable platform for accurate game results and secure gameplay, dedicated to supporting our users. Our site ensures 100% safety and quick access to the latest game updates directly from the source. Bookmark us for daily result updates and share the link with your fellow online lottery enthusiasts."
        </Text>
      </Box>



      <Box
        bgGradient="linear(to-r, #d2122e, black)"
        p={6}
        boxShadow="md"
        borderRadius="md"
      >
        <Heading as="h3" size="lg" mb={4} color="white">
          Our Values
        </Heading>
        <Stack spacing={2} color="white">
          <Text fontSize={['sm', 'md', 'lg']} color="white">
          Innovation: Our goal is to constantly redefine the limits of what can be achieved.
          </Text>
          <Text fontSize={['sm', 'md', 'lg']} color="white">
          Quality: We uphold an unwavering standard of excellence for all our products and services.

          </Text>
          <Text fontSize={['sm', 'md', 'lg']} color="white">
          Sustainability: We are dedicated to fostering a positive environmental influence.
          </Text>
        </Stack>
      </Box>
      <Box w="100%" overflow="hidden">
        <Text
          fontSize={['md', '2xl']}
          color="red "
          fontWeight="bold"
          marginTop={['30px', '50px', '100px']}
          className="running-text"
        >
          ||
          Please be cautious and only download our app from Maharaj567.com to avoid scams. ||
        </Text>
      </Box>
    </Box>
  );
};

export default AboutUs;
