import React, { useState, useEffect } from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
  Spinner,
  useColorMode,
  useMediaQuery,
  Badge,
  Stack,
  Icon,
  Flex,
} from '@chakra-ui/react';
import { FaBell, FaRegBell } from 'react-icons/fa';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles

const NotificationDetails = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const { colorMode } = useColorMode();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get('https://mainproject-ao3x.onrender.com/notifications');
        setNotifications(response.data);
        setLoading(false);
        markAllAsRead();
      } catch (error) {
        console.error('Error fetching notifications:', error);
        setLoading(false);
      }
    };

    const markAllAsRead = async () => {
      try {
        await axios.post('https://mainproject-ao3x.onrender.com/notifications/markAsRead');
      } catch (error) {
        console.error('Error marking notifications as read:', error);
      }
    };

    const interval = setInterval(fetchNotifications, 5000);
    fetchNotifications();

    return () => clearInterval(interval);
  }, []);

  const statusColorMap = {
    info: '#E1F7F5',
    success: '#ECFFE6',
    warning: '#FEFFD2',
    error: '#FF8989',
  };

  const formatDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const renderEmptyState = () => (
    <Flex
      direction="column"
      align="center"
      justify="center"
      h="100%"
      p={10}
      textAlign="center"
      borderRadius="lg"
      bg={colorMode === 'light' ? 'gray.200' : 'gray.800'}
      boxShadow="md"
    >
      <Icon as={FaRegBell} w={16} h={16} mb={4} color={colorMode === 'light' ? 'gray.500' : 'gray.400'} />
      <Heading as="h4" size="md" mb={2}>
        No Notifications Yet
      </Heading>
      <Text fontSize="lg" color={colorMode === 'light' ? 'gray.700' : 'gray.300'}>
        Stay tuned for the latest updates and notifications!
      </Text>
    </Flex>
  );

  return (
    <Box p={[4, 6]} w="100%" minHeight="80vh">
      <VStack spacing={4} align="stretch">
        {loading ? (
          <Spinner size="xl" />
        ) : notifications.length > 0 ? (
          notifications.map((notif) => {
            const status = notif.status || 'info';
            return (
              <Box
                key={notif._id}
                p={[4, 6]}
                borderRadius="md"
                bg={`${statusColorMap[status]}`}
                color={colorMode === 'light' ? 'black' : 'white'}
                boxShadow="md"
                _hover={{ shadow: 'lg', transform: 'scale(1.02)' }}
                transition="all 0.3s"
                display="flex"
                flexDirection="column"
              >
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Heading as="h1" size={isMobile ? 'md' : 'lg'} fontWeight={600}>
                    {notif.title}
                  </Heading>
                  <Icon as={FaBell} w={6} h={6} color={colorMode === 'light' ? 'blue.500' : 'blue.300'} />
                </Stack>
                <Box mt={2} overflow="hidden">
                  <ReactQuill
                    value={notif.description}
                    readOnly
                    theme="snow"
                    modules={{ toolbar: false }}
                    formats={[
                      'font', 'header', 'list', 'bullet', 'bold', 'italic', 'underline', 'link', 'image', 'color', 'background'
                    ]}
                    style={{ height: 'auto', maxWidth: '100%', border: 'none', outline: 'none' }} // Inline style for ReactQuill
                  />
                </Box>
                <Badge
                  mt={2}
                  fontSize="0.8em"
                  variant="solid"
                  bg={'blue.300'}
                  borderRadius={5}
                  alignSelf="flex-start"
                >
                  {formatDate(notif.createdAt)}
                </Badge>
              </Box>
            );
          })
        ) : (
          renderEmptyState()
        )}
      </VStack>
      <style>
        {`
          .ql-container {
            border: none !important;
          }

          .ql-editor {
            border: none !important;
          }

          .ql-snow .ql-toolbar {
            border: none !important;
          }
          
          /* Ensure content fits within box */
          .ql-editor p {
            margin: 0;
            padding: 0;
          }
          
          .ql-editor img {
            max-width: 100%;
            height: auto;
          }
        `}
      </style>
    </Box>
  );
};

export default NotificationDetails;
