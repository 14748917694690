// ChartPage.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Grid, Flex, Box, Heading,  } from '@chakra-ui/react';
import '../Style/Chart.css';
import ScrollButton from '../ScrollButton';
const chartTypes = [
  'SRIDEVI',
  'TIME BAZAR',
  // 'MADHUR DAY',
  'MILAN DAY',
  'RAJDHANI DAY',
  'KALYAN',
  'SRIDEVI NIGHT',
  // 'MADHUR NIGHT',
  'MILAN NIGHT',
  
  'KALYAN NIGHT',
  'RAJDHANI NIGHT',
  // 'MAIN BAZAR',
  // 'SUPREME NIGHT',
  // 'SUPREME DAY',
  // 'SRIDEVI DAY',
  // 'KARNATAKA DAY',
  // Add more chart types as needed
];

// const chartTypes2 = [
//   'SRIDEVI',
//   'TIME BAZAR',
//   'MADHUR DAY',
//   'MILAN DAY',
//   'KALYAN',
//   'SRIDEVI NIGHT',
//   'MADHUR NIGHT',
//   'MILAN NIGHT',
//   'MAIN BAZAR',
//   'RAJDHANI DAY',
//   'RAJDHANI NIGHT',
//   'KALYAN NIGHT',
//   'SUPREME NIGHT',
//   'SUPREME DAY',
//   'SRIDEVI DAY',
//   'TARA MORNING',
//   'TARA DAY',
//   'TARA NIGHT',
//   'KARNATAKA DAY',
 
//   // Add more chart types as needed
// ];
const ChartPage = () => {
  const navigate = useNavigate();
  const handleCardClick = (chartType) => {
    navigate(`/pannel/table/${chartType}`);
  };
  const handleCardClick2 = (chartType) => {
    navigate(`/records/table/${chartType}`);
  };

  return (
    <>
     <Flex direction="column" className='flexmain' alignItems="center">
        <Heading as="h1" className='difText'size={["lg", "lg", "xl", "2xl"]}  textAlign="center" mt={8} mb={8}>
          Pana Charts
        </Heading>
        <Grid
          width='100%'
          padding={{ base: '10px', md: '20px' }}
          marginTop={{ base: '20px', md: '50px' }}
          templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} // One card in a row for small screens, two cards for medium and above
          gap={4}
          justifyItems="center"
          
        >
          {chartTypes.map((chartType) => (
            <Box
              key={chartType}
              p={4}
              borderWidth="1px"
              borderRadius="md"
              boxShadow="lg"
              className='box_pannel '
              width={{ base: '100%', md: '90%' }}
              textAlign="center"
              _hover={{
                background: '#e3001a7e',
                fontWeight: 'bold',
                border: '3px solid #E3001B',
                transform: 'scale(1.02)',
              }}
              onClick={() => handleCardClick(chartType)}
              style={{
                cursor: 'pointer',
              }}
              
            >
              <Link to={`/pannel/table/${chartType}`} className="apple-link-text2" >
                {chartType}
              </Link>
            </Box>
          ))}
        </Grid>
        <Heading as="h1" className='apple-link-text' size={["lg", "lg", "xl", "2xl"]}  textAlign="center" mt={8} mb={4}>
          Jodi Charts
        </Heading>
        <Grid
          width='100%'
          padding={{ base: '10px', md: '20px' }}
          templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
          gap={4}
          marginTop='50px'
          marginBottom={{ base: '100px', md: '190px' }}
          justifyItems="center"
        >
          {chartTypes.map((chartType) => (
            <Box
              key={chartType}
              p={4}
              borderWidth="1px"
              borderRadius="md"
              boxShadow="lg"
              width={{ base: '100%', md: '90%' }}
              textAlign="center"
              className='box_jodi '
              _hover={{
                background: 'rgba(0, 0, 0, 0.546)',
                fontWeight: 'bold',
                border: '2px solid black',
                transform: 'scale(1.02)',
              }}
              onClick={() => handleCardClick2(chartType)}
              style={{
                cursor: 'pointer',
              }}
            >
              <Link to={`/records/table/${chartType}`} className="apple-link-text">
                {chartType}
              </Link>
            </Box>
          ))}
        </Grid>
      </Flex>
      <ScrollButton/>
    </>
  );
};

export default ChartPage;