import React, { createContext, useState, useContext, useEffect } from 'react';

// Create a context for the heading
const HeadingContext = createContext();

// Create a provider component
export const HeadingProvider = ({ children }) => {
  // Initialize state with the value from localStorage or a default value
  const [heading, setHeading] = useState(() => {
    return localStorage.getItem('heading') || ''; // Retrieve heading from localStorage if available
  });

  // Update localStorage whenever heading changes
  useEffect(() => {
    localStorage.setItem('heading', heading);
  }, [heading]);

  return (
    <HeadingContext.Provider value={{ heading, setHeading }}>
      {children}
    </HeadingContext.Provider>
  );
};

// Custom hook to use the Heading context
export const useHeading = () => {
  return useContext(HeadingContext);
};
