import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
  Box,
  Heading,
  Spinner,
  Text,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Tooltip,
  VStack,
  useMediaQuery,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import { MdScreenRotation } from 'react-icons/md';
import { jwtDecode } from 'jwt-decode';

const TransactionDetails = ({ transaction }) => (
  <Box
    w="100%"
    p="4"
    boxShadow="md"
    borderWidth="1px"
    borderRadius="md"
  >
    <VStack align="stretch" spacing="4" w="100%">
      {/* Date */}
      <Text><b>Date:</b> {new Date(transaction.createdAt).toLocaleDateString()}</Text>

      {/* Transaction ID */}
      {transaction._id && (
        <Text><b>Transaction ID:</b> {transaction._id}</Text>
      )}

      {/* Type */}
      {transaction.type && (
        <Text><b>Type:</b> {transaction.type}</Text>
      )}

      {/* Amount */}
      {transaction.amount && (
        <Text><b>Amount:</b> ₹{transaction.amount}</Text>
      )}

      {/* Bet Type */}
      {transaction.betType && (
        <Text><b>Bet Type:</b> {transaction.betType}</Text>
      )}

      {/* Status */}
      {transaction.status && (
        <Text><b>Status:</b> {transaction.status}</Text>
      )}
      {/* Selected Numbers */}
      {transaction.selectedNumbers && transaction.selectedNumbers.length > 0 && (
        <Box>
          <Text>
            <b>Selected Numbers:</b>
          </Text>
          {transaction.selectedNumbers.map((item, index) => (
            <Text key={index}>{`{${item.number}: ${item.amount}}`}</Text>
          ))}
        </Box>
      )}


      {/* Result */}
      {transaction.betResult && (
        <Text><b>Result:</b> {transaction.betResult}</Text>
      )}
      {transaction.betResult === 'Win' && transaction.winningNumber && (
        <>
          <Text color="green.500"><b>Winning Number:</b> {transaction.winningNumber}</Text>
        </>
      )}

      {/* Winning Amount */}
      {transaction.betResult === 'Win' && transaction.winningAmount && (
        <>
          <Text color="green.500"><b>Winning Amount:</b> ₹{transaction.winningAmount}</Text>
          <Text color="green.400" fontWeight="bold" fontSize="sm" mt="2">
            🎉 Congratulations You Won! 🥳
          </Text>
        </>
      )}

      {/* Loss Message */}
      {transaction.betResult === 'Loss' && (
        <Text color="red.400" fontWeight="bold" fontSize="sm" mt="2">
          Better Luck Next Time! 😢
        </Text>
      )}
    </VStack>
  </Box>


);

const WalletTransactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isLandscape, setIsLandscape] = useState(false);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [processedBets, setProcessedBets] = useState(new Set()); // Track processed bets

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      const username = decodedToken.username;
      fetchTransactions(username);
    } else {
      setError('Token not found');
    }
  }, []);

  const fetchTransactions = async (username) => {
    setLoading(true);
    try {
      const response = await axios.get(`https://mainproject-ao3x.onrender.com/account/${username}/history`);
      const allTransactions = response.data;

      // Sort transactions by createdAt in ascending order
      allTransactions.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));


      setTransactions(allTransactions);
      setLoading(false);
    } catch (error) {
      setError('No Transactions Found');
      setLoading(false);
    }
  };


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' });
  };

  const calculateBalance = (index, transaction) => {
    let balance = 0;
    let previousBalance = 0;

    // Ensure that the balance starts from a valid number
    for (let i = 0; i <= index; i++) {
      const currentTransaction = transactions[i];

      if (!currentTransaction || typeof currentTransaction.amount !== 'number') {
        continue; // Skip invalid transactions
      }

      previousBalance = balance; // Save balance before transaction

      switch (currentTransaction.type) {
        case 'Deposit':
          balance += currentTransaction.amount;
          break;
        case 'Withdrawal':
          balance -= currentTransaction.amount;
          break;
        case 'Bet':
          balance -= currentTransaction.betAmount; // Subtract bet amount
          break;
        case 'Win': // Handle 'Win' type separately as a transaction
          balance += currentTransaction.winningAmount || 0; // Add winning amount
          break;
        default:
          break;
      }
    }

    // Ensure the balance is a valid number
    balance = isNaN(balance) ? 0 : balance;
    return { previousBalance, balance };
  };



  const enterFullScreen = async () => {
    const docElement = document.documentElement;
    if (docElement.requestFullscreen) {
      await docElement.requestFullscreen();
    } else if (docElement.mozRequestFullScreen) { // Firefox
      await docElement.mozRequestFullScreen();
    } else if (docElement.webkitRequestFullscreen) { // Chrome, Safari
      await docElement.webkitRequestFullscreen();
    } else if (docElement.msRequestFullscreen) { // IE/Edge
      await docElement.msRequestFullscreen();
    }
  };

  const handleOrientationToggle = async () => {
    if (window.screen.orientation) {
      try {
        await enterFullScreen();  // Enter full-screen mode first
        const currentOrientation = window.screen.orientation.type;
        const newOrientation = currentOrientation.includes('landscape') ? 'portrait' : 'landscape';
        await window.screen.orientation.lock(newOrientation);
        setIsLandscape(newOrientation === 'landscape');
        console.log(`Orientation locked to ${newOrientation}`);
      } catch (error) {
        console.error('Failed to lock orientation', error);
      }
    } else {
      console.warn('Screen orientation API is not supported in this browser.');
    }
  };


  return (
    <Box p={4} minHeight="80vh">
      {/* Header with Responsive Font Size */}
      <Heading
        as="h2"
        size={{ base: "md", sm: "lg", md: "lg", lg: "xl" }}
        mb={6}
        color="teal.600"
        fontWeight="bold"
      >
        Wallet Statement
      </Heading>

      {/* IconButton for mobile with responsive margins */}
      <Flex justify="flex-start" align="center" mb={6}>
        {isMobile && (
          <IconButton
            aria-label="Switch orientation"
            icon={<MdScreenRotation />}
            onClick={handleOrientationToggle}
            variant="outline"
            colorScheme="teal"
            size={{ base: "sm", sm: "md", md: "lg" }}
            mr={4}
          />
        )}
      </Flex>

      {/* Loading State */}
      {loading ? (
        <Flex justify="center" align="center" h="300px">
          <Spinner size="xl" color="teal.500" />
        </Flex>
      ) : (
        <>
          {/* Transactions List */}
          {transactions.length > 0 ? (
            <Box overflowX="auto">
              <Table variant="simple" size={{ base: "sm", md: "md" }}>
                <Thead bg="teal.100" textAlign={'left'}>
                  <Tr>
                    <Th fontSize={{ base: "sm", md: "md" }}>Date</Th>
                    <Th fontSize={{ base: "sm", md: "md" }}>Game/Bet Details</Th>
                    <Th fontSize={{ base: "sm", md: "md" }}>Previous Amount</Th>
                    <Th fontSize={{ base: "sm", md: "md" }}>Transaction Amount</Th>
                    <Th fontSize={{ base: "sm", md: "md" }}>Current Amount</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {transactions
                    .filter(transaction => transaction.type !== 'Loss')
                    .map((transaction, index) => {
                      const { previousBalance, balance } = calculateBalance(index, transaction);
                      const isBet = transaction.type === 'Bet';
                      const betAmount = isBet ? `₹${transaction.betAmount}` : '';
                      const transactionAmount = transaction.type === 'Deposit'
                        ? `+ ₹${transaction.amount}`
                        : transaction.type === 'Win'
                          ? `+ ₹${transaction.winningAmount || 0}`
                          : `- ₹${transaction.amount || transaction.betAmount}`;

                      const transactionColor = transaction.type === 'Deposit'
                        ? 'green.500'
                        : transaction.type === 'Win'
                          ? 'green.500'
                          : 'red.500';

                      return (
                        <React.Fragment key={index}>
                          <Tr _hover={{ bg: 'gray.100' }} fontSize={{ base: "sm", md: "md" }}>
                            <Td>
                              <Tooltip label={formatDate(transaction.createdAt)} aria-label="Date" placement="top">
                                <Text fontWeight="semibold" fontSize={{ base: "sm", md: "md" }}>
                                  {formatDate(transaction.createdAt)}
                                </Text>
                              </Tooltip>
                            </Td>
                            <Td>
                              <Accordion allowToggle>
                                <AccordionItem border={'none'}>
                                  <AccordionButton _hover={{ bg: 'gray.200' }}>
                                    <Flex justify="space-between" textAlign="left" w="full">
                                      <Box flex="1">
                                        <Text fontWeight="semibold" color="black" fontSize={{ base: "sm", md: "md" }}>
                                          {transaction.gameName || (transaction.type === 'Deposit' ? 'Deposit' : 'Withdrawal')}
                                          {transaction.category ? ` - ${transaction.category}` : ''}
                                          {/* Check if status is declared */}
                                          {isBet && transaction.status === 'Completed' && (
                                            <Text
                                              as="span"
                                              color={transaction.betResult === 'Win' ? 'green.500' : 'red.500'}
                                              fontSize={{ base: "sm", md: "md" }}
                                            >
                                              {transaction.betResult === 'Win' ? '+(Win)' : '-Loss'}
                                            </Text>
                                          )}

                                          {/* Additional 'Win' type transactions */}
                                          {transaction.type === 'Win' && (
                                            <Text color="green.500" as="span" fontSize={{ base: "sm", md: "md" }}>
                                              (Win)
                                            </Text>
                                          )}
                                        </Text>
                                      </Box>
                                      <Box>
                                        <AccordionIcon color={'black'} />
                                      </Box>
                                    </Flex>
                                  </AccordionButton>

                                  <AccordionPanel pb={4}>
                                    <TransactionDetails transaction={transaction} />
                                  </AccordionPanel>
                                </AccordionItem>
                              </Accordion>

                            </Td>

                            <Td>
                              <Text fontSize={{ base: "sm", sm: "lg" }} fontWeight="bold" color="gray.700">
                                ₹{previousBalance}
                              </Text>
                            </Td>
                            <Td color={transactionColor} fontSize={{ base: "sm", sm: "lg" }} fontWeight="bold">
                              {transactionAmount}
                            </Td>
                            <Td>
                              <Text fontSize={{ base: "sm", sm: "lg" }} fontWeight="bold" color="gray.700">
                                ₹{balance}
                              </Text>
                            </Td>
                          </Tr>
                        </React.Fragment>
                      );
                    })}
                </Tbody>
              </Table>
            </Box>
          ) : (
            <Text fontSize={{ base: "sm", sm: "md" }}>No transactions available.</Text>
          )}
        </>
      )}
    </Box>

  );
};

export default WalletTransactions;
