import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaPlay, FaClock } from 'react-icons/fa';
import {
  Box,
  Text,
  Center,
  IconButton,
  Flex,
  Grid,
  Tooltip,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Divider,
  Heading,
} from '@chakra-ui/react';
import gameTimingData from './gameTimingData.json';
import { keyframes } from '@emotion/react';

// Define the pulse animation
const pulseAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(72, 187, 120, 0.7); // No circle at start
  }
  30% {
    box-shadow: 0 0 0 10px rgba(72, 187, 120, 0.3); // Expanding circle
  }
  50% {
    box-shadow: 0 0 0 0 rgba(72, 187, 120, 0.7); // Back to initial state
  }
`;

// Icon animation keyframes
const iconAnimation = keyframes`
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.2) rotate(10deg); }
`;
// Card Component
const Card = ({ title, openResultTime, openBidLastTime, closeResultTime, closeBidLastTime }) => {
  const [marketStatus, setMarketStatus] = useState("");
  const [canPlay, setCanPlay] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure(); // New alert modal disclosure
  const navigate = useNavigate(); // Initialize navigate
  useEffect(() => {
    const checkMarketStatus = () => {
      const now = new Date();
      const currentTime = now.getHours() * 60 + now.getMinutes(); // Current time in minutes since midnight

      // Handle the special condition for resetting the market at 12:30 AM
      if (now.getHours() === 0 && now.getMinutes() >= 0 && now.getMinutes() < 30) {
        setMarketStatus("Betting is currently closed");
        setCanPlay(false);
        return;
      }

      // Reset market status at 12:30 AM
      if (now.getHours() === 0 && now.getMinutes() === 30) {
        setMarketStatus("Betting is running now");
        setCanPlay(true);
        return;
      }

      // Logic to handle open/close times for the day
      const openBidLast =
        parseInt(openBidLastTime.split(':')[0]) * 60 +
        parseInt(openBidLastTime.split(':')[1]) +
        (openBidLastTime.includes('PM') && !openBidLastTime.startsWith('12') ? 720 : 0);

      const closeBidLast =
        parseInt(closeBidLastTime.split(':')[0]) * 60 +
        parseInt(closeBidLastTime.split(':')[1]) +
        (closeBidLastTime.includes('PM') && !closeBidLastTime.startsWith('12') ? 720 : 0);

      if (currentTime < openBidLast) {
        setMarketStatus("Betting is running now");
        setCanPlay(true);
      } else if (currentTime >= openBidLast && currentTime < closeBidLast) {
        setMarketStatus("Betting is running for close");
        setCanPlay(true);
      } else {
        setMarketStatus("Betting is closed for today");
        setCanPlay(false);
      }
    };

    checkMarketStatus();
    const timer = setInterval(checkMarketStatus, 60000); // Check every minute
    return () => clearInterval(timer);
  }, [openBidLastTime, closeBidLastTime]);


  const handleIconClick = () => {
    if (!canPlay) {
      onOpen(); // Open alert modal if betting is closed
    } else {
      onOpen(); // Open details modal if betting is running
    }
  };

  const handlePlayClick = () => {
    if (!canPlay) {
      onAlertOpen(); // Open alert modal if betting is closed
    } else {
      navigate(`/gamescreen/${encodeURIComponent(title)}`); // Pass title as state
    }
  };


  return (
    <>
      <Center>

        <Box
          as="article"
          w={{ base: '90%', sm: '240px', md: '280px', lg: '330px' }} // Responsive width
          h={{ base: '120px', sm: '140px', md: '150px', lg: '160px' }} // Responsive height
          m={2}
          borderRadius="20px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          bg={'white'} // Background color
          border="1px solid"
          borderColor="cyan.500"
          color="white" // Text color (white to contrast with background)
          p={4}
          transition="transform 0.3s ease, box-shadow 0.3s ease"
          _hover={{
            transform: 'translateY(-5px)',
            boxShadow: '0 0 30px #0C1844, 0 0 30px #00f2ff',
            borderColor: 'cyan.600',
          }}
          overflow="hidden"
        >
          {/* Clock Icon Button */}
          <Tooltip label="Game Time" borderRadius={'20px'} ml={5} fontSize={{ base: 'xs', md: 'sm'}} placement="top" bg="yellow.100" color="black">
            <IconButton
              icon={<FaClock />}
              fontSize={{ base: "18px", sm: "20px", md: "22px" }} // Responsive icon size
              color="#FFC700"
              variant="ghost"
              aria-label="Time details"
              animation={`${iconAnimation} 2s ease-in-out infinite`}
              _hover={{
                color: "yellow.400",
                transform: "scale(1.1) rotate(15deg)",
                transition: "transform 0.3s ease-in-out, color 0.3s ease-in-out", // Smooth hover transition
              }}
              onClick={handleIconClick}
            />
          </Tooltip>
          {/* Text Content */}
          <Flex flexDirection="column" textAlign="center" mx="auto">
            <Text
              fontSize={{ base: 'md', sm: 'lg', md: 'xl' }} // Responsive font size
              fontWeight="bold" // Bold font
              mb={1}
              color="black" // Text color for contrast with background
            >
              {title}
            </Text>
            <Text
              fontSize={{ base: 'sm', sm: 'md', md: 'lg' }} // Responsive font size for market status
              fontWeight="semibold" // Make it bold
              color={marketStatus.includes("running") ? 'green.700' : 'red.400'} // Dynamic color based on status
            >
              {marketStatus}
            </Text>
          </Flex>
          {/* Play Icon Button */}
          <Tooltip label="Play Games" fontSize={{ base: 'xs', md: 'sm'}} borderRadius={'20px'} mr={5}  placement="top" bg="green.100" color="black">
            <IconButton
              icon={<FaPlay />}
              fontSize={{ base: '18px', sm: '20px', md: '22px' }} // Responsive icon size
              colorScheme="green"
              variant="ghost"
              aria-label="Play Game"
              onClick={handlePlayClick}
              _hover={{
                color: 'green.400',
                transform: 'scale(1.2)', // Slight increase in size for hover effect
                transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out', // Smooth transition
                boxShadow: '0 0 0 4px rgba(72, 187, 120, 0.6)', // Circle effect on hover
              }}
              _focus={{
                outline: 'none', // Removing the default focus outline
              }}
              _active={{
                transform: 'scale(1.15)', // Slightly reduce scale when the button is clicked
                boxShadow: '0 0 0 6px rgba(72, 187, 120, 0.8)', // Slightly increase the circle effect on click
              }}
              borderRadius="50%" // Circle shape around the icon
              p="10px" // Padding to create space for the circle effect
              transition="transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out" // Smooth transition
              sx={{
                animation: `${pulseAnimation} 2s infinite`, // Apply the pulse animation
              }}
            />
          </Tooltip>
        </Box>

      </Center>
      {/* Modal for Clock Icon */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          bg="white"
          color="black"
          maxWidth={{ base: "90%", md: "400px" }}
          borderRadius="lg"
        >
          <ModalHeader textAlign="center" color="red.500">
            {title}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="md" textAlign="center" mb={4} color="purple.600">
              {new Date().toLocaleString('en-US', { weekday: 'long' })}
            </Text>
            <Text
              fontSize="sm"
              textAlign="center"
              color={marketStatus.includes("running") ? "green.500" : "red.500"}
              mb={4}
            >
              {canPlay ? marketStatus : "Betting is closed for today."}
            </Text>
            <Flex justify="space-between" mt={4}>
              <Box
                textAlign="center"
                border="1px solid"
                borderColor="red.300"
                borderRadius="lg"
                p={2}
                w="45%"
              >
                <Text fontSize="md" fontWeight="bold" color="red.400">
                  Open
                </Text>
                <Divider borderColor="red.200" />
                <Text fontSize="sm" mt={2} color="orange">
                  OPEN RESULT TIME
                </Text>
                <Text fontSize="lg" fontWeight="bold" mb={2} color="black">
                  {openResultTime}
                </Text>
                <Text fontSize="sm" color="orange">
                  OPEN BID LAST TIME
                </Text>
                <Text fontSize="lg" fontWeight="bold" color="black">
                  {openBidLastTime}
                </Text>
              </Box>
              <Box
                textAlign="center"
                border="1px solid"
                borderColor="red.300"
                borderRadius="lg"
                p={2}
                w="45%"
              >
                <Text fontSize="md" fontWeight="bold" color="red.400">
                  Close
                </Text>
                <Divider borderColor="red.200" />
                <Text fontSize="sm" mt={2} color="orange">
                  CLOSE RESULT TIME
                </Text>
                <Text fontSize="lg" fontWeight="bold" mb={2} color="black">
                  {closeResultTime}
                </Text>
                <Text fontSize="sm" color="orange">
                  CLOSE BID LAST TIME
                </Text>
                <Text fontSize="lg" fontWeight="bold" color="black">
                  {closeBidLastTime}
                </Text>
              </Box>
            </Flex>
            <Center mt={4}>
              <Box
                as="button"
                bg={canPlay ? "red.500" : "red.500"}
                color="white"
                px={6}
                py={2}
                borderRadius="md"
                _hover={{ bg: canPlay ? "red.400" : "red.400" }}
                onClick={canPlay ? handlePlayClick : onClose}
              >
                {canPlay ? "Play Now" : "OK"}
              </Box>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>



      {/* Alert Modal for Betting Closed */}
      <Modal isOpen={isAlertOpen} onClose={onAlertClose} isCentered>
        <ModalOverlay />
        <ModalContent bg="white" color="grey.900" maxWidth="300px">
          <ModalHeader textAlign="center" color="red.500">
            Betting Closed
          </ModalHeader>
          <ModalCloseButton color={'red.500'} />
          <ModalBody>
            <Text fontSize="md" textAlign="center">
              Betting is closed for today.
            </Text>
            <Center mt={4}>
              <Box
                as="button"
                bg="red.600"
                color="white"
                px={6}
                py={2}
                borderRadius="md"
                _hover={{ bg: "red.500" }}
                onClick={onAlertClose}
              >
                Ok
              </Box>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

// Main App Component
const App = () => {
  return (
    <Box bg="#240A34" minH="80vh" p={5}>
      <Heading
        as="h1"
        size="xl"
        fontSize={{ base: '2xl', md: '4xl', lg: '5xl' }}
        textAlign="center"
        color="white"
        mb={5}
        fontWeight="extrabold" // Bold text for a modern feel
        letterSpacing="wider" // Slight letter spacing for a more elegant look
        backgroundClip="text" // Apply gradient text
        bgGradient="linear(to-l, #00f2ff, #ff0080)" // Gradient background for text
        transition="transform 0.5s ease-in-out"

      >
        Game Market
      </Heading>

      <Grid templateColumns="repeat(auto-fill, minmax(300px, 1fr))" gap={2}>
        {gameTimingData.map((card, index) => (
          <Card
            key={index}
            title={card.title}
            openResultTime={card.openResultTime}
            openBidLastTime={card.openBidLastTime}
            closeResultTime={card.closeResultTime}
            closeBidLastTime={card.closeBidLastTime}
          />
        ))}
      </Grid>
    </Box>
  );
};

export default App;
