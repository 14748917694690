import React from 'react';
import {
  Box,
  Container,
  Stack,
  Text,
  Image,
  Flex,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom';

// SocialIcon Component
function SocialIcon({ icon, href }) {
  return (
    <Link
      to={href}
      style={{ display: 'flex', alignItems: 'center', color: 'grey', padding: '8px', fontSize: '20px' }}
    >
      {icon}
    </Link>
  );
}

// Footer Component
function Footer() {
  return (
    <Box
      bg="black" // Background color maintained as black
      py={5}
      color="#d2122e" // Preserved text color
      width="100%"
      marginBottom="-26px"
    >
      <Container maxW="6xl">
        <Stack
          direction={{ base: 'column', md: 'row' }}
          align="center"
          justify="space-between"
          spacing={{ base: 4, md: 6 }}
        >
          <Box>
            <Link to="/" fontWeight="bold">
              <Image
                src="/images/logo-no-background.png"
                alt="Logo"
                width={{ base: '120px', md: '180px' }}
                height="auto"
              />
            </Link>
          </Box>
          <Stack spacing={2} align="center">
            <Text fontWeight="bold" fontSize={{ base: 'sm', md: 'md' }}>
              Company
            </Text>
            <Stack spacing={1} textAlign="center">
              <Link to="/charts/page">Charts</Link>
              <Link to="/gamerules">Game Rules</Link>
              <Link to="/aboutus">About us</Link>
              <Link to="/terms">Terms & Conditions</Link>
            </Stack>
          </Stack>
          <Stack spacing={2} align="center">
            <Text fontWeight="bold" fontSize={{ base: 'sm', md: 'md' }}>
              Stay Connected
            </Text>
            <Stack direction="row" spacing={3} align="center">
              <SocialIcon icon={<FaTwitter />} href="/twitter" />
              <SocialIcon icon={<FaYoutube />} href="/youtube" />
              <SocialIcon icon={<FaInstagram />} href="/instagram" />
            </Stack>
          </Stack>
        </Stack>
        <Text textAlign="center" color="grey" fontSize={{ base: 'xs', md: 'sm' }} mt={4}>
          Copyright © 2024 Maharaj 567. All rights reserved
        </Text>
      </Container>
    </Box>
  );
}

// Main Layout Component
function Layout({ children }) {
  return (
    <Flex direction="column" >
      {/* Header or Navbar can be added here */}
      <Box flex="1">{children}</Box>
      <Footer />
    </Flex>
  );
}

export default Layout;
