import { useState, useEffect, useCallback } from 'react';
import { useWebSocket } from './WebSocketContext';
import { jwtDecode } from 'jwt-decode';

const useWalletBalance = () => {
  const { balance, setBalance } = useWebSocket();
  const [walletBalance, setWalletBalance] = useState(balance || 0); // Initialize with context balance or 0
  const [loading, setLoading] = useState(true);
  const pollingInterval = 1000; // Set to 1 second for more frequent updates

  // Function to fetch wallet balance
  const fetchWalletBalance = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      const username = decodedToken.username;

      if (username) {
        try {
          const response = await fetch(`https://mainproject-ao3x.onrender.com/account/${username}/balance`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.ok) {
            const data = await response.json();
            const newBalance = data.balance !== undefined ? data.balance : 0;
            setWalletBalance(newBalance); // Update state immediately
            setBalance(newBalance); // Update the context balance
          } else {
            setWalletBalance(0); // Set to 0 if response isn't ok
            setBalance(0); // Set context to 0
          }
        } catch (error) {
          console.error('Fetch error:', error);
          setWalletBalance(0); // Handle fetch errors by setting balance to 0
          setBalance(0); // Update context with 0 on error
        } finally {
          setLoading(false); // Stop loading once fetch is done
        }
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false); // Stop loading if token doesn't exist
    }
  }, [setBalance]);

  // Immediately fetch wallet balance when the component mounts
  useEffect(() => {
    fetchWalletBalance(); // Fetch balance once immediately

    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const username = decodedToken.username;

        if (username) {
          const intervalId = setInterval(fetchWalletBalance, pollingInterval); // Poll after initial fetch

          return () => {
            clearInterval(intervalId); // Clean up the interval
          };
        } else {
          console.error('Username not found in token');
          setLoading(false);
        }
      } catch (error) {
        console.error('Error decoding token:', error);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [fetchWalletBalance, pollingInterval]);

  // Sync wallet balance with the context balance when it changes
  useEffect(() => {
    setWalletBalance(balance); // Update local state if context balance changes
  }, [balance]);

  return { walletBalance, setWalletBalance, fetchWalletBalance, loading };
};

export default useWalletBalance;
