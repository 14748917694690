import React from 'react';
import { Heading, Flex, Box } from "@chakra-ui/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet, faMobile, faPhone, faSquare } from '@fortawesome/fontawesome-free-solid';

const cardData = [
  { title: 'Instant Deposit And Withdraw', icon: faWallet, text: 'Instant Deposit And Withdraw', color: '#FF4500' }, // OrangeRed
  { title: 'Trusted App', icon: faMobile, text: 'Trusted App', color: '#4169E1' }, // RoyalBlue
  { title: 'Customer Support', icon: faPhone, text: 'Customer Support', color: '#800080' }, // Purple
  { title: 'Fast Result', icon: faSquare, text: 'Fast Result', color: '#228B22' }, // ForestGreen
];

const StaticCard = ({ icon, text, color }) => {
  return (
    <Box
      bg="white"
      border={`2px solid ${color}`}
      borderRadius="20px"
      flexBasis={{ base: "calc(50% - 20px)", md: "calc(38% - 20px)", lg: "260px" }} // 50% on mobile, 33% on medium screens
      height={{ base: "160px", md: "180px", lg: "260px" }}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      cursor="pointer"
      transition="transform .2s, border-color .2s"
      margin="5px"
      _hover={{
        transform: "translateY(-5px)",
        boxShadow: "0 8px 16px rgba(0, 0, 0, .3)"
      }}
      padding={[4, 6]}
      textAlign="center"
    >
      <Box
        fontSize={{ base: "30px", md: "40px" }}
        marginBottom="15px"
        color={color}
        transform="scale(1)"
        transition="transform .2s"
        _hover={{
          transform: "scale(1.2)" // Scale icon on hover
        }}
      >
        {icon && <FontAwesomeIcon icon={icon} />}
      </Box>
      <Heading as="h3" fontSize={{ base: "14px", md: "18px" }} fontWeight="700" margin="0" color={color}>
        {text}
      </Heading>
    </Box>
  );
};

const StaticCardComponent = () => {
  return (
    <Box bgGradient="linear-gradient(#c35533, rgba(182, 214, 255, .374))" padding="20px" className="app">
      <Heading
        fontFamily='Hammersmith One, sans-serif'
        fontSize={{ base: '24px', md: '40px' }}
        textAlign="center"
        mb={['20px', '20px', '60px']}
        className="title"
      >
        Why should you play on <Box as="span" bgGradient="linear-gradient(90deg, #ff001e, #000)" bgClip="text" WebkitBackgroundClip="text" color="transparent">
          Maharaj 567
        </Box>
      </Heading>
      <Flex
        flexWrap="wrap"
        justifyContent="space-evenly" // Keeps the cards evenly distributed
        className="card-container"
      >
        {cardData.map((card, index) => (
          <StaticCard
            key={index}
            icon={card.icon}
            text={card.text}
            color={card.color}
          />
        ))}
      </Flex>
    </Box>
  );
};

export default StaticCardComponent;
