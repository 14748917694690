import React from 'react';
import {
  Box,
  Center,
  Heading,
  SimpleGrid,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import './Style/allthree.css';
import ContactCard from './EnquiryCard';

const TermAndConditions = () => {
  return (
    <Box p={4} >
      <Center>
        <Heading as="h1" size={['lg', 'xl', 'xl', '2xl']} color={'#d2122e'}>
          Terms and Conditions
        </Heading>
      </Center>
      <SimpleGrid columns={[1, null, 2]} marginTop={['10px', null, '50px']} spacing={6}>
        <Box boxShadow="md" p={4} borderRadius="md" bg='orange.100'>
          <Text fontSize={['sm', 'md', 'lg']}>
            Matka gambling or satta is a form of betting and lottery which originally involved betting on the opening and closing rates of cotton transmitted from the New York Cotton Exchange to the Bombay Cotton Exchange. It originates from before the era of Indian independence when it was known as Ankada Jugar ("figures gambling"). In the 1960s, the system was replaced with other ways of generating random numbers, including pulling slips from a large earthenware pot known as a matka, or dealing with playing cards.
          </Text>
        </Box>
        <VStack
          boxShadow="md"
          p={4}
          borderRadius="md"
          spacing={4}
          alignItems="flex-start"
          bg='orange.100'
        >
          <Text fontSize={['sm', 'md', 'lg']}>
          "We're happy to help, but please keep in mind that our answers are just educated guesses and might not always be accurate. We can't take responsibility for any mistakes, and we're not able to pay for any possible errors. Use the information at your own risk and consider seeking professional advice if needed. we are not responsible if your guessing is not true, and we are not able to pay.  Thanks for understanding!"
          </Text>
          {/* Add more Text components for additional terms and conditions */}
        </VStack>
      </SimpleGrid>
      <Spacer mt={4} />
      <ContactCard />
      <Spacer mt={4} />
      <Box w="100%" overflow="hidden">
        <Text
          fontSize={['md', '2xl']}
          color="red "
          fontWeight="bold"
          marginTop={['30px', '50px', '100px']}
          className="running-text"
        > 
  || Please be cautious and only Play in our site from Maharaj567.services to avoid scams. ||
        </Text>
      </Box>
    </Box>
  );
};

export default TermAndConditions;
