import React, { useState } from 'react';
import {
  Box,
  Heading,
  Text,
  UnorderedList,
  ListItem,
  Flex,
  IconButton,
  Tooltip,
  useClipboard,
  useToast,
  Stack,
  Spinner,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Divider,
  VStack,
  HStack,
} from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import QRCode from 'react-qr-code';

const Deposit = () => {
  const upiID = 'maharaj567@hdfc';
  const { hasCopied, onCopy } = useClipboard(upiID);
  const toast = useToast();
  const [isCopying, setIsCopying] = useState(false);

  const handleCopy = () => {
    setIsCopying(true);
    try {
      onCopy();
      toast({
        title: 'Success',
        description: 'UPI ID has been copied to your clipboard.',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to copy UPI ID.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setIsCopying(false);
    }
  };

  // Generate UPI QR Code payload
  const upiPayload = `upi://pay?pa=${upiID}&pn=Your Name&cu=INR`;

  return (
    <Box
      maxW={{ base: '90%', md: '600px' }}
      mx="auto"
      mt="10"
      p="6"
      borderWidth="1px"
      borderRadius="lg"
      boxShadow="xl"
      bg="white"
      mb="10"
      color="gray.800"
    >
      <VStack spacing={4}>
        <Heading
          as="h2"
          fontSize={{ base: 'xl', md: '2xl', lg: '4xl' }}
          textAlign="center"
          color="teal.500"
          fontWeight="bold"
          textShadow="1px 1px 2px rgba(0,0,0,0.2)" // Optional: Adds a subtle text shadow
          mt={5} // Optional: Adjusts top margin for spacing
          mb={5}
        >
          Deposit
        </Heading>

        <VStack spacing={2} mb={4}>
          <QRCode value={upiPayload} size={128} />
          <HStack>
            <Text fontWeight="bold" fontSize="lg" color="green.500">
              {upiID}
            </Text>
            <Tooltip label={hasCopied ? "Copied!" : "Copy UPI ID"} aria-label="Copy UPI ID">
              <IconButton
                icon={isCopying ? <Spinner size="sm" /> : <CopyIcon />}
                onClick={handleCopy}
                size="sm"
                colorScheme="teal"
                variant="ghost"
                aria-label="Copy UPI ID"
              />
            </Tooltip>
          </HStack>
        </VStack>
        <Box bg={'green.100'} borderRadius={'20px'} color={'green.800'} p={'7px'}>
          <Text textAlign="center">
            To add funds to your wallet, please make a payment to the following UPI ID:
          </Text>
          <Text textAlign="center">
            Once the payment is completed, the funds will be credited to your wallet.
          </Text>
        </Box>
        <Box mt={6} width="100%" textAlign="left">
          <Heading as="h3" size="md" color="teal.500" mb={2}>
            Deposit Rules
          </Heading>
          <UnorderedList styleType='inherit' color="gray.600" spacing={2}>
            <ListItem>Minimum Deposit: INR 100</ListItem>
            <ListItem>Maximum Deposit: INR 100,000</ListItem>
            <ListItem>Deposits are credited instantly once confirmed.</ListItem>
            <ListItem>Ensure the UPI ID is correct before making a payment.</ListItem>
            <ListItem>Transactions made outside of business hours (10 AM to 11 PM IST) may take longer to process.</ListItem>

            {/* Add more rules as needed */}
          </UnorderedList>
        </Box>
        <Divider />
        <Flex justifyContent="center" mt={4} wrap="wrap">
          <Stack direction="row" spacing={6} align="center">
            <img src="../images/Paytm.png" alt="Paytm" style={{ width: '60px', height: '60px' }} />
            <img src="../images/Phone.png" alt="PhonePe" style={{ width: '80px', height: '80px' }} />
            <img src="../images/Gpay.png" alt="Google Pay" style={{ width: '60px', height: '60px' }} />
          </Stack>
        </Flex>
        <Divider />
        <Box mt={6} width="100%">
          <Heading as="h3" size="md" textAlign="center" color="teal.500" mb={2}>
            Important Information
          </Heading>
          <Alert status="info" borderRadius="md">
            <AlertIcon />
            <Box flex="1">
              <AlertTitle>Note:</AlertTitle>
              <AlertDescription display="block">
                Please allow up to 10 minutes for the funds to reflect in your wallet. If you experience any issues, contact our support team.
              </AlertDescription>
            </Box>
          </Alert>
        </Box>
        <Divider />
      </VStack>
    </Box>
  );
};

export default Deposit;




