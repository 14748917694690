import React from 'react';
import { Box, Heading, Text, Icon, Button, VStack } from '@chakra-ui/react';
import { FaExclamationTriangle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const DefaultScreen = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/'); // Adjust this path to your intended page
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      bgGradient="linear(to-r, red.500, yellow.500)"
      padding={4}
    >
      <VStack
        spacing={6}
        maxW="lg"
        p={8}
        bg="white"
        boxShadow="2xl"
        rounded="md"
        textAlign="center"
      >
        <Icon as={FaExclamationTriangle} w={12} h={12} color="red.500" />
        <Heading size="lg" color="gray.700">
          Access Restricted
        </Heading>
        <Text color="gray.600">
          Sorry, you cannot access this page directly. Please navigate through the{' '}
          <strong>Homepage.</strong> 
        </Text>
        <Button
          colorScheme="red"
          size="lg"
          onClick={handleNavigate}
          _hover={{ bg: 'red.600' }}
        >
          Go to HomePage
        </Button>
      </VStack>
    </Box>
  );
};

export default DefaultScreen;
