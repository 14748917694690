import React from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
  List,
  ListItem,
  Flex,
  useColorModeValue,
} from '@chakra-ui/react';


const Howtoplay = () => {
  const cardBgColor = useColorModeValue('white', 'gray.800');

  return (
    <Box
      p={6}
      borderWidth="1px"
      borderRadius="lg"
      bgColor={cardBgColor}
      boxShadow="lg"
      mx={['2', '4', '6', '8']} // Adjust margins for different screen sizes
    >
      <VStack spacing={6}>
        <Heading as="h2" size={['sm', 'lg', 'md', 'lg']}  textAlign="center" color="red.500" bg="red.100" borderRadius={'full'} p={2}>
          About the Game
        </Heading>
        <Text fontSize={['sm', 'md', 'lg', 'xl']} bg={'pink.100'}   p={6} borderRadius={'3xl'}>
          Satta is a broad word to describe <i>“betting”</i> in India. The
          Matka game is sometimes referred to as Indian Satta Matka because of
          its early popularity. If you are interested in betting games, you can
          check out the numbers game. The game of online satta matka is pretty
          simple to understand and play. With very little study and effort, you
          can become an online matka play king and earn a huge amount.
        </Text>
      </VStack>
      <Flex direction={['column', 'row']} justify={['center', 'space-between']}>
        <VStack spacing={6} mt={6} mb={10} flex={['1', '1', '1', '2']}>
          <Heading as="h2" size={['sm', 'lg', 'md', 'lg']}  textAlign="center" color="red.500" bg="red.100" p={2} borderRadius={'full'}>
            How to Play Matka on Maharaj567?
          </Heading>
          <List styleType="disc" fontSize={['sm', 'md', 'lg', 'xl']} bg={'pink.100'} p={6} borderRadius={'3xl'}>
            <ListItem  marginLeft={'15px'}>
              Step 1 is to pick (3) numbers from 0–9. For example 2,3,4 would be
              your first picked random numbers from given 0–9. To add more thrill
              and substance to the diversion, the numbers are then
              included/added (2 + 3 + 4) and a last number is given. In this
              example, it is 9. Now, you only have to keep one digit of this
              number, that is the last one. In this example, it will be the 9. So
              your first draw would be 2,3,4 * 9.
            </ListItem>
            <ListItem  marginLeft={'15px'}>
              There are also a second set of numbers which is drawn. The process
              is similar just like step 1. The rules for picking 2nd number is
              exactly same as the first draw. As a random case, let's accept the
              numbers 7,8,9. This gives us a sum of 24, we again just only keep
              the last digit so our last pick for the second draw of numbers is
              7,8,9. * 4
            </ListItem>
         
            <ListItem  marginLeft={'15px'}>
              Our last card would resemble this: 2,3,4 * 9 X 7,8,9. * 4. Here is
              an example card.
            </ListItem>
          </List>
        </VStack>

        <VStack spacing={6} mt={6} ml={[0, 0, 0, 8]} mb={10} flex={['1', '1', '1', '2']}>
          <Heading as="h2"  size={['sm', 'lg', 'md', 'lg']}  textAlign="center" color="red.500" bg="red.100" p={2} borderRadius={'full'}>
            How to Add Money in Maharaj567?
          </Heading>
          <List styleType="round" fontSize={['sm', 'md', 'lg', 'xl']}  bg={'pink.100'} p={6} borderRadius={'3xl'}>
            <ListItem  marginLeft={'15px'}>Minimum Deposit is 100 /- Rs</ListItem>
            <ListItem  marginLeft={'15px'}>Minimum Withdraw is 1000/- Rs</ListItem>
            <ListItem  marginLeft={'15px'}>Maximum Withdraw Is 2 Lakh Per Day.</ListItem>
            <ListItem  marginLeft={'15px'}>  Withdrawal request can be placed between 11 am to 10 pm, all 7 days.  </ListItem>
            <ListItem  marginLeft={'15px'}>Withdraw Is Available On Saturday &amp; Sunday Also.</ListItem>
            <ListItem  marginLeft={'15px'}>Withdraw Is Not Available On Festivals.</ListItem>
            <ListItem  marginLeft={'15px'}>The amount of money you deposit accordingly the points will be added to  your corresponding ID</ListItem>
            <ListItem  marginLeft={'15px'}>1 point = Rs. 1/-</ListItem>
          </List>
        </VStack>
      </Flex>

      <VStack spacing={6} mt={6} mb={10}>
        <Heading as="h2"  size={['sm', 'lg', 'md', 'lg']}  textAlign="center" color="red.500" bg="red.100" p={2} borderRadius={'full'}>
          How Do You Win At Matka?
        </Heading>
        <Text fontSize={['sm', 'md', 'lg', 'xl']}  bg={'pink.100'} p={6} borderRadius={'3xl'}>
          To win at Matka Satta, you have various rate payouts, ranging from 9/1 to
          999/1. You can bet on the chance of all numbers coming up to the first,
          last, or any other type of bet allowed by the Matka gambling bookie. For
          this reason, it can be an attractive game because of the payout multiples,
          but the game is merely a game of chance and therefore cannot be beaten. It
          requires luck to win, but many are superstitious about their numbers and
          always play them, just like the lottery games.
        </Text>
      </VStack>

      <VStack spacing={6} mt={6} mb={10}>
        <Heading as="h2"  size={['sm', 'lg', 'md', 'lg']}  textAlign="center" color="red.500" bg="red.100" p={2} borderRadius={'full'}>
          Satta Matka Fees &amp; Odds
        </Heading>
        <Text fontSize={['sm', 'md', 'lg', 'xl']}  bg={'pink.100'} p={6} borderRadius={'3xl'}>
          The Satta betting agent should only take a maximum of 5% of your wager
          amount assuming you win. Because the game is all luck and neither the
          agent nor the bettor has an advantage, the bookie should take home 5% on
          every Rupee wagered. Heavy betting on a certain number or combination of
          numbers can be a dangerous scenario. If those numbers come up, it is very
          likely your bookie will vanish because he can’t afford to cover the
          wagers. When the cards and numbers are picked, generally at 9 PM and 12
          at night the winners are declared. This is how a payout would occur.
        </Text>
      </VStack>

      <VStack spacing={6} mt={6} mb={10}>
        <Heading as="h2"  size={['sm', 'lg', 'md', 'lg']}  textAlign="center" color="red.500" bg="red.100" p={2} borderRadius={'full'}>
          History of Satta Matka
        </Heading>
        <Text fontSize={['sm', 'md', 'lg', 'xl']} bg={'pink.100'} p={6} borderRadius={'3xl'}>
          The origins of Indian Matka gambling involve wagering on the daily price
          of cotton according to the Bombay and the New York Cotton Exchange.
          Nowadays, Matka betting, or Satta King, is a popular lottery-style game.
          It involves the selection of random numbers in the hopes of hitting the
          right number combination. The winner becomes the Satta King and they earn
          the bulk of the prize pool. The legendary Ratan Khatri is popularly known
          as the Matka King. After his arrest in 1995, he had to discontinue his
          games, and now crooks are running the game he loved so much by fixing the
          numbers. A big fall from grace, but his name remains legendary among Satta
          Matka games in Mumbai.
        </Text>
      </VStack>

      <VStack spacing={6} mt={6} mb={10}>
        <Heading as="h2"  size={['sm', 'lg', 'md', 'lg']}  textAlign="center" color="red.500" bg="red.100" p={2} borderRadius={'full'}>
          Conclusion on Satta Matka
        </Heading>
        <Text fontSize={['sm', 'md', 'lg', 'xl']} bg={'pink.100'}  p={6} borderRadius={'3xl'}>
          Big-time enthusiasts still play this game, and they remember the days of
          Ratan having celebrities pull draws for him. The game has since lost most
          of its following. Indians have chosen the faster-paced action of online
          cricket betting or live dealer casinos on their mobile devices. IPL
          betting draws people like nothing else in India, and fans wait eagerly to
          place bets on the event.
        </Text>
      </VStack>
    </Box>
  );
};

export default Howtoplay;
