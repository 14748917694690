import React from 'react';
import { Text, Flex, Skeleton } from '@chakra-ui/react';
import useWalletBalance from './hook/useWalletBalance'; // Adjust the import path as necessary

const Balance = () => {
  const { walletBalance, loading } = useWalletBalance();
  const responsiveFontSizes = [ 'sm', 'sm', 'sm', 'md'];

  return (
    <Flex alignItems="center" width="100%" justifyContent="center">
  <Skeleton 
    isLoaded={!loading} 
    height="20px" 
    width={['40px', '40px', '43px', '60px']} // Adjust width responsively
    startColor="yellow" 
    endColor="yellow"
  >
    <Flex alignItems="center" justifyContent="center">
      <Text 
        fontSize={responsiveFontSizes} 
        fontWeight="bold" 
        color="yellow"
      >
        {walletBalance !== null ? walletBalance : 0}
      </Text>
    </Flex>
  </Skeleton>
</Flex>

  );
};

export default Balance;
