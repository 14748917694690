import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Flex,
  IconButton,
  Spacer,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  Text,
  Image,
  VStack,
  HStack,
  Link,
  Link as ChakraLink,
  CloseButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Avatar,
  Button,
  Icon
} from "@chakra-ui/react";
import { MdMenu } from "react-icons/md";
import { Link as RouterLink } from "react-router-dom";
import { IoWalletOutline } from "react-icons/io5";
import { useBreakpointValue } from "@chakra-ui/react";
import './Style/NavbarPage.css';
import Balance from "./Balance";
import NotificationBell from './NotificationBell';
import { useAuth } from './AuthContext';

// Function to convert image to base64
const getBase64 = (url, callback) => {
  const xhr = new XMLHttpRequest();
  xhr.onload = function () {
    const reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
};

const Navbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMobile, setIsMobile] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [imageSrcs, setImageSrcs] = useState({ logo: '', profile: '' });
  const logoutTimerRef = useRef(null);

  const { user, logout } = useAuth();

  useEffect(() => {
    if (user) {
      startLogoutTimer();
      startLoginSync();

      const eventListener = isMobile ? 'touchstart' : 'mousemove';
      window.addEventListener(eventListener, handleUserActivity);

      return () => {
        stopLogoutTimer();
        stopLoginSync();
        window.removeEventListener(eventListener, handleUserActivity);
      };
    }
  }, [isMobile, user]);

  useEffect(() => {
    const loadImages = async () => {
      const logoUrl = '/images/logo-no-background.png';
      const profileUrl = '/images/profileimage.png';

      const logoCached = localStorage.getItem('logo');
      const profileCached = localStorage.getItem('profile');

      if (logoCached && profileCached) {
        setImageSrcs({ logo: logoCached, profile: profileCached });
        setImagesLoaded(true);
      } else {
        getBase64(logoUrl, (logoBase64) => {
          localStorage.setItem('logo', logoBase64);
          setImageSrcs((prev) => ({ ...prev, logo: logoBase64 }));
        });
        getBase64(profileUrl, (profileBase64) => {
          localStorage.setItem('profile', profileBase64);
          setImageSrcs((prev) => ({ ...prev, profile: profileBase64 }));
        });
        setImagesLoaded(true);
      }
    };

    loadImages();
  }, []);

  const handleUserActivity = () => {
    resetLogoutTimer();
  };

  const toggleMobileView = () => {
    setIsMobile(!isMobile);
    if (!isOpen) {
      onOpen();
    } else {
      onClose();
    }
  };

  const handleLinkClick = () => {
    if (isMobile) {
      onClose();
    }
  };

  const handleLogout = () => {
    logout(() => {
      window.location.href = '/'; // Redirect to home or login page after logout
    });
  };

  const startLogoutTimer = () => {
    logoutTimerRef.current = setTimeout(handleLogout, 1200000); // 20 minutes in milliseconds
  };

  const stopLogoutTimer = () => {
    clearTimeout(logoutTimerRef.current);
  };

  const resetLogoutTimer = () => {
    stopLogoutTimer();
    startLogoutTimer();
  };

  const startLoginSync = () => {
    localStorage.setItem('login', Date.now());
  };

  const stopLoginSync = () => {
    localStorage.removeItem('login');
  };

  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  return (
    <>
      <Box
        bg="#040404"
        position="sticky"
        top="0"
        zIndex="sticky"
        p={0}
        px={4}
      >
        <Flex alignItems="center">
          <IconButton
            icon={<MdMenu />}
            onClick={toggleMobileView}
            className="mobile-icon-center"
            background="transparent"
            color="#d2122e"
            fontWeight="extrabold"
            fontSize="20px"
            size="md"
            padding="2px"
            _hover={{ transform: "scale(1.2)" }}
            _active={{ transform: "scale(0.9)" }}
            transition="transform 0.2s ease"
            display={{ base: "flex", md: "none" }}

          />

          <Box className="logo" flex="1">
            <RouterLink to="/" fontWeight="bold" className="nav-link">
              <Image
                className="nav_logo"
                alt="logoimage"
                src={imagesLoaded ? imageSrcs.logo : '/images/logo-no-background.png'}
                objectFit="cover"
              />
            </RouterLink>
          </Box>

          <Spacer />
          {isSmallScreen && (
            <Link
              as={RouterLink}
              to="/notification"
              className="nav-link"
              borderRadius={'20px'}
        
            >
              <NotificationBell />
            </Link>
          )}
          {isSmallScreen && user && (
            <Flex
              alignItems="center"
              onClick={handleLinkClick}
              cursor="pointer"
              border="2px solid"
              borderColor="yellow"
              borderRadius="100px"
              p={1}
              ml={-3}
              flexWrap="wrap"
            >
              <Icon as={IoWalletOutline} boxSize={4} color="yellow" mr={2} />
              <Text fontSize="sm" color="yellow" mr={1}>
                <Balance />
              </Text>
            </Flex>
          )}

          <HStack
            as="nav"
            spacing={1}
            display={{ base: "none", md: "flex" }}
            className="nav-links"
          >
            <Link
              as={RouterLink}
              to="/"
              className="nav-link"
              onClick={handleLinkClick}
            >
              Home
            </Link>
            <Link
              as={RouterLink}
              to="/charts/page"
              className="nav-link"
              onClick={handleLinkClick}
            >
              Charts
            </Link>
            <Link
              as={RouterLink}
              to="/gamerules"
              className="nav-link"
              onClick={handleLinkClick}
            >
              Game Rules
            </Link>

            <Link
              as={RouterLink}
              to="/aboutus"
              className="nav-link"
              onClick={handleLinkClick}
            >
              About Us
            </Link>
            <Link
              as={RouterLink}
              to="/terms"
              className="nav-link"
              onClick={handleLinkClick}
            >
              T&C
            </Link>

            <Link
              as={RouterLink}
              to="/notification"
              className="nav-link"
            >
              <NotificationBell />
            </Link>
            {!user && (
              <>
                <Link
                  as={RouterLink}
                  to="/login"
                  className="nav-link"
                  onClick={handleLinkClick}
                >
                  Login
                </Link>
              </>
            )}
            {user && (
              <Flex
                alignItems="center"
                onClick={handleLinkClick}
                cursor="default"
              >

                <Text fontSize="lg" color={'yellow'} fontWeight='700' mr={2}>
                  {user.username}
                </Text>
                <Text
                  fontSize="sm"
                  display="flex"
                  alignItems="center"
                  border="1px solid"
                  borderColor="yellow"
                  color={'yellow'}
                  style={{ backdropFilter: 'blur(8px)' }}
                  borderRadius={'100px'}
                  px={2}
                  py={1}
                  mr={'1px'}
                >
                  <Icon as={IoWalletOutline} boxSize={6} mt={1} mr={2} />
                  <Balance />
                </Text>
              </Flex>
            )}
            {user && (
              <Menu>
                <MenuButton
                  as={Button}
                  rounded={'full'}
                  variant={'link'}
                  cursor={'pointer'}
                  minW={0}
                >
                  <Avatar
                    size={'sm'}
                    border={'2px solid #001F3F'}
                    p={1}
                    src={imagesLoaded ? imageSrcs.profile : '/images/profileimage.png'}
                  />
                </MenuButton>
                <MenuList alignItems="start"  bg="black" fontWeight='500' border={'none'} borderRadius={"none"} marginTop={'15.5px'}borderBottomRadius={5}  marginRight={'-16px'}>
                  <MenuItem
                    as={RouterLink}
                    to="/deposit"
                     bg="black"
                  >
                    Deposit
                  </MenuItem>
                  
                  <MenuItem
                    as={RouterLink}
                    to="/withdraw"
                     bg="black"
                  >
                    Withdraw
                  </MenuItem>
                  <MenuItem
                    as={RouterLink}
                    to="/credit"
                     bg="black"
                  >
                    Credit History
                  </MenuItem>
                  <MenuItem
                    as={RouterLink}
                    to="/debit"
                     bg="black"
                  >
                    Debit History
                  </MenuItem>
                  <MenuItem
                    as={RouterLink}
                    to="/statement"
                     bg="black"
                  >
                    Statement
                  </MenuItem>
                  
                  <MenuItem
                    as={RouterLink}
                    to="/bethistory"
                     bg="black"
                  >
                    Bet History
                  </MenuItem>
                  <MenuItem
                    as={RouterLink}
                    to="/addbankdeatils"
                     bg="black"
                  >
                   Add Bank Deatils
                  </MenuItem>
                  <MenuItem
                    onClick={handleLogout}
                    bg="black"
                    color="red.300"
                    py={2}
                    px={4}
                    textAlign={'center'}
                    marginBottom={'-7px'}
                    fontWeight='extrabold'
                    fontSize="17px"
                    textDecoration={'underline'}

                    cursor="pointer"
                    boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
                    transition="background-color 0.3s, transform 0.3s, box-shadow 0.3s"
                    _active={{ transform: "scale(0.95)" }}
                  >
                    Logout
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </HStack>
        </Flex>
      </Box>

      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent bg="transparent" className="drawer-content" style={{ backdropFilter: 'blur(50px)' }}>
          <Flex justifyContent='flex-start' alignItems="center">
            {user && user.username && ( // Check if user is logged in and username exists
              <Text
                fontWeight="bold"
                fontSize="sm"
                color="yellow"
                bgGradient="linear(to-r, red.500, black)"
                p={2}
                ml={4}
                borderTopLeftRadius={'30px'}
                borderBottomEndRadius={'30px'}
                textAlign="center"
                _hover={{
                  bgGradient: 'linear(to-r, red.600, black)',
                  transform: 'scale(1.05)',
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                }}
                transition="all 0.3s ease-in-out"
                boxShadow="0px 2px 5px rgba(0, 0, 0, 0.1)"
              >
                Hey, {user.username}
              </Text>

            )}
            <IconButton
              aria-label="Close navigation menu"
              icon={<CloseButton />}
              onClick={onClose}
              className="mobile-icon-center"
              background={"transparent"}
              color={"rgba(226,107,189,1)"}
              width={"10%"}
              marginLeft="auto"
              marginRight={'20px'}
              marginTop={'10px'}
              borderRadius={'50px'}
              marginBottom={'10px'}
            />
          </Flex>

          <DrawerBody>
            <VStack spacing={4} alignItems="start">


              <ChakraLink
                as={RouterLink}
                to="/"
                className="nav-link"
                onClick={() => {
                  handleLinkClick();
                  onClose();
                }}

              >
                Home
              </ChakraLink>
              <ChakraLink
                as={RouterLink}
                to="/gamerules"
                className="nav-link"
                onClick={() => {
                  handleLinkClick();
                  onClose();
                }}

              >
                Game Rules
              </ChakraLink>
              <ChakraLink
                as={RouterLink}
                to="/charts/page"
                className="nav-link"
                onClick={() => {
                  handleLinkClick();
                  onClose();
                }}

              >
               Charts
              </ChakraLink>
              <ChakraLink
                as={RouterLink}
                to="/aboutus"
                className="nav-link"
                onClick={() => {
                  handleLinkClick();
                  onClose();
                }}

              >
                About Us
              </ChakraLink>
              <ChakraLink
                as={RouterLink}
                to="/terms"
                className="nav-link"
                onClick={() => {
                  handleLinkClick();
                  onClose();
                }}

              >
                T&Conditions
              </ChakraLink>
              {user ? (
                <>
                  {/* Add private links here */}
                  <ChakraLink
                    as={RouterLink}
                    to="/deposit"
                    className="nav-link"
                    onClick={() => {
                      handleLinkClick();
                      onClose();
                    }}

                  >
                    Deposit
                  </ChakraLink>
                  <ChakraLink
                    as={RouterLink}
                    to="/withdraw"
                    className="nav-link"
                    onClick={() => {
                      handleLinkClick();
                      onClose();
                    }}

                  >
                    Withdraw
                  </ChakraLink>
                  <ChakraLink
                    as={RouterLink}
                    to="/credit"
                    className="nav-link"
                    onClick={() => {
                      handleLinkClick();
                      onClose();
                    }}

                  >
                    Credit History
                  </ChakraLink> <ChakraLink
                    as={RouterLink}
                    to="/debit"
                    className="nav-link"
                    onClick={() => {
                      handleLinkClick();
                      onClose();
                    }}

                  >
                    Debit History
                  </ChakraLink>
                  <ChakraLink
                    as={RouterLink}
                    to="/addbankdeatils"
                    className="nav-link"
                    onClick={() => {
                      handleLinkClick();
                      onClose();
                    }}

                  >
                    Add Bank
                  </ChakraLink>
                  <ChakraLink
                    as={RouterLink}
                    to="/bethistory"
                    className="nav-link"
                    onClick={() => {
                      handleLinkClick();
                      onClose();
                    }}

                  >
                    Bet History
                  </ChakraLink>
                  <ChakraLink
                    as={RouterLink}
                    to="/statement"
                    className="nav-link"
                    onClick={() => {
                      handleLinkClick();
                      onClose();
                    }}

                  >
                    Statement
                  </ChakraLink>
                  <Button
                    onClick={handleLogout}
                    bg="transparent"
                    color="red.300"
                    borderRadius="50px"

                    fontWeight="bold"
                    fontSize="18px"
                    textDecoration={'underline'}
                    _hover={{ opacity: '0.8' }}
                    cursor="pointer"
                    boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
                    transition="background-color 0.3s, transform 0.3s, box-shadow 0.3s"
                    _active={{ transform: "scale(0.95)" }}
                  >
                    Logout
                  </Button>
                </>
              ) : (
                <>
                  <ChakraLink
                    as={RouterLink}
                    to="/login"
                    className="nav-link"
                    onClick={() => {
                      handleLinkClick();
                      onClose();
                    }}

                  >
                    Login
                  </ChakraLink>
                </>
              )}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

    </>
  );
};

export default Navbar;
