

import React from 'react';
import { Icon } from '@chakra-ui/react';
import { FaPhone, FaComment } from 'react-icons/fa';
import { FaTelegramPlane } from "react-icons/fa";
import ScrollButton from './ScrollButton';
import CardList from './gameCard';
import { useQuery } from 'react-query';
import {
  Grid,
  Box,
  Container,
  Flex,
  Heading,
  Image,
  Link,
  Text,
  Button,
  Spinner,
  VStack,
  Center,
  IconButton
} from '@chakra-ui/react';

import WelcomeBanner from './Welcome';
import Card from './Card';
import './Style/HomePage.css';
import LuckyNumber from './LuckyNumber';
import StaticCard from './StaticCard';

import ContactCard from './EnquiryCard';
import { useCardContext } from './ContextProvider/CardContext';
import { useNavigate } from 'react-router-dom';

const fetchTableData = async () => {
  try {
    // Fetch data from the API
    const response = await fetch('https://mainproject-ao3x.onrender.com/data');

    // Check if the response is ok
    if (!response.ok) {
      throw new Error('Network response was not ok. Please check your internet connection.');
    }

    // Parse the response JSON
    const data = await response.json();

    // Array of selected game types (can be modified as needed)
    const chartTypes = [
      'SRIDEVI',
      'TIME BAZAR',
      'MILAN DAY',
      'RAJDHANI DAY',
      'KALYAN',
      'SRIDEVI NIGHT',
      'MILAN NIGHT',
      'KALYAN NIGHT',
      'RAJDHANI NIGHT',
      // Add or uncomment more game types as needed
    ];

    // Filter the data to show only items where the h8 field matches a selected game type
    const filteredData = data.filter(item => chartTypes.includes(item.h8));

    // If no data matches the selected game types, show an error message
    if (filteredData.length === 0) {
      throw new Error('No data found for the selected games.');
    }

    // Sort the filtered data to match the order of chartTypes
    const sortedData = chartTypes
      .map(type => filteredData.find(item => item.h8 === type))
      .filter(Boolean); // Filter out undefined items in case some types don't exist in the data

    // Return the sorted data
    return sortedData;
  } catch (error) {
    // Handle errors: log and rethrow or show an alert
    console.error('Error fetching or processing data:', error);
    throw new Error(`Failed to load data: ${error.message}`);
  }
};


// Call the function and log the result
fetchTableData()
// .then(data => console.log(data))
// .catch(error => console.error(error));

const HeroBanner = () => {
  return (
    <Box
      className='bannerbg'
      color="white"
      py={{ base: '30px', md: '70px' }}
    >
      <Center flexDirection={{ base: 'column-reverse', md: 'row' }} alignItems="center">
        
        {/* Left Section - Text Content */}
        <VStack 
          spacing={{ base: '4', md: '6' }} 
          alignItems={{ base: 'center', md: 'flex-start' }} 
          px={{ base: '4', md: '8' }} 
          maxW={{ base: '100%', md: '50%' }} 
          textAlign={{ base: 'center', md: 'left' }}
        >
          <Heading
            as="h1"
            fontSize={{ base: '2xl', md: '4xl', lg: '5xl' }}
            fontWeight="extrabold"
            textShadow="4px 10px 4px rgba(192,17,46,0.8)"
            lineHeight="1.2"
            letterSpacing="wider"
          >
            Ready to Play Games? 
          </Heading>

          <Heading
            fontSize={{ base: 'xl', md: '3xl', lg: '4xl' }}
            fontWeight="bold"
            textShadow="4px 10px 4px rgba(192,17,46,0.8)"
            mt={{ base: '10px', md: '20px' }}
          >
            Message us on WhatsApp to get your ID and start playing!
          </Heading>

          <Text
            fontSize={{ base: 'md', md: 'xl', lg: '2xl' }}
            fontWeight="medium"
            mt={{ base: '10px', md: '20px' }}
            letterSpacing="wider"
          >
            <span style={{ color: '#FFDD00', fontWeight: 'bold' }}>Message now</span> and get started today! 
          </Text> 
        </VStack>

        {/* Right Section - Image */}
        <Image
          src="../images/Screenshot__86_-removebg.png"
          w={{ base: '70%', md: '315px', lg: "500px" }}
          maxH="500px"
          h="auto"
          _hover={{ transform: 'scale(1.1)' }}
          transition="transform 0.5s ease-in-out"
          mb={{ base: '20px', md: '0' }}
        />
        
      </Center>
    </Box>
  );
};


function MainButton() {
  const navigate = useNavigate(); // Use useNavigate for navigation
  const telegramChannelUrl = 'https://t.me/mahaaraj567';
  const watsappChannelUrl='https://chat.whatsapp.com/FRyZZOM2UYTEElyqYFwAAW';
  const handleButtonClick = () => {
    // You can replace "/new-page" with the path you want to navigate to
    navigate('/download-app');
  };

  return (
    <Box
      minHeight="60vh"
      className='mainButton'
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Flex
        wrap="wrap"
        gap={12}
        alignItems="center"
        maxWidth={{ base: '100%', md: '800px', lg: '1200px' }}
        paddingX={4}
        justifyContent="center"
        border={'none'}
      >
        <Link href={telegramChannelUrl} isExternal >
        <Button
          as="div"
          width={{ base: '150px', md: '200px' }}
          height={{ base: '150px', md: '200px' }}
          borderRadius="50%"
          bgColor="blue.100"
          color="blue.500"
          fontWeight="bold"
          fontSize="16px"
          borderWidth="10px"
          borderColor="blue.500"
          cursor="pointer"
          transition="transform 0.3s ease-in-out, border 0.3s ease-in-out"
          _hover={{ transform: 'scale(1.1)', borderColor: 'blue.800' }}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          marginY={4}
        >
          <Icon as={FaTelegramPlane} boxSize="36px" mb="10px" transition="transform 0.3s ease-in-out" _hover={{ transform: 'scale(1.2)' }} />
          <Text>Telegram</Text>
        </Button>
        </Link>
        <Link href={watsappChannelUrl} isExternal >
        <Button
          as="div"
          width={{ base: '150px', md: '200px' }}
          height={{ base: '150px', md: '200px' }}
          borderRadius="50%"
          bgColor="green.100"
          color="green.500"
          fontWeight="bold"
          fontSize="16px"
          borderWidth="10px"
          borderColor="green.500"
          cursor="pointer"
          transition="transform 0.3s ease-in-out, border 0.3s ease-in-out"
          _hover={{ transform: 'scale(1.1)', borderColor: 'green.800' }}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          marginY={4}
        >
          <Icon as={FaComment} boxSize="36px" mb="10px" transition="transform 0.3s ease-in-out" _hover={{ transform: 'scale(1.2)' }} />
          <Text>WhatsApp</Text>
        </Button>
        </Link>
      </Flex>
    </Box>
  );
};
const PricingCard = ({ title, price, fontSize }) => (
  <Flex alignItems='center' width='100%' p='5' mb='4'>
    <Text
      fontSize={fontSize}
      width={['70%', '50%', '50%']}
      textAlign={['center', 'left', 'left']}
      fontWeight='600'
      color='#d2122e'
      lineHeight='1.5em'
      css={{
        '@media screen and (min-width: 1024px) and (max-width: 1024px)': {
          fontSize: '18px', // Adjust the font size for screens with a width of exactly 1024px
        },
      }}

    >
      {title}
    </Text>
    <Button
      width={['80%', '50%', '50%']}
      bgColor='#e6a3ad'
      color='#d2122e'
      fontWeight='bold'
      fontSize={fontSize}
      ml='2'
      css={{
        '@media screen and (min-width: 1024px) and (max-width: 1024px)': {
          fontSize: '16px', // Adjust the font size for screens with a width of exactly 1024px
        },
      }}
    >
      {price}
    </Button>
  </Flex>
);

function HomePage() {
  const { data: tableData, isLoading, isError } = useQuery('tableData', fetchTableData);
  const { updateCard } = useCardContext();
  const telegramChannelUrl = 'https://t.me/mahaaraj567';
  const handleDownload = (item) => {
    // Implement your download logic here
    // console.log('Downloading:', item.name);
  };

  return (
    <>
      <WelcomeBanner />
      <HeroBanner />
      <MainButton />
      <Box
        position="fixed"
        bottom={{ base: "80px", md: "80px" }} /* Adjust the positioning for different screen sizes */
        right={{ base: "10px", md: "10px" }} /* Adjust the positioning for different screen sizes */
        zIndex="999"
      >
        {/* href={telegramChannelUrl} */}
        <Link href={telegramChannelUrl} isExternal>
          <IconButton
            aria-label="Telegram Channel"
            icon={<FaTelegramPlane />}
            backgroundColor="#0088cc" /* Set the background color to Telegram's blue */
            color="white" /* Set the text color */
            borderRadius="50%"
            width={{ base: "40px", md: "45px" }} /* Set different widths for base and medium (md) screen sizes */
            height={{ base: "40px", md: "45px" }} /* Set different heights for base and medium (md) screen sizes */
            boxShadow="md"
            _hover={{ backgroundColor: '#006699' }} /* Adjust hover background color */
          />
        </Link>
      </Box>

      <StaticCard />
      <Box
        width='auto'
        height={['auto', 'auto', 'auto', 'auto']}
        textAlign='center'
        mx='auto'
        bg={'rgba(182, 214, 255, 0.374)'}
      >
        <Center>
          <Heading fontSize={['25px', '30px', '40px']} mt={['10px', '10px', '20px']}>
            Game <Text as='span' ml='2px' className='difText'>
              Rates ₹
            </Text>
          </Heading>
        </Center>
        <Flex
          flexDirection={['column', 'column', 'column', 'row']}
          gap={['10px', '10px', '20px']}
          mt={['10px', '30px', '50px']}
          mx={['20px', '20px', '20px', 'auto']}
        >
          <Box width={['100%', '100%', '100%']} mx='auto' p={['2', '4', '4']}>
            <Flex direction='column' alignItems='flex-start'>
              <PricingCard title="Single Digit" price="1 RS KA 9.5  Rs" fontSize={['sm', 'md', 'xl']} />
              <PricingCard title="Jodi Digit" price="1 RS KA 95 Rs" fontSize={['sm', 'md', 'xl']} />
              {/* <PricingCard title="Red Brackets" price="1 RS KA 95 Rs" fontSize={['sm', 'md', 'xl']} /> */}
              <PricingCard title="Single Pana" price="1 RS KA 150 Rs" fontSize={['sm', 'md', 'xl']} />
            </Flex>
          </Box>
          <Box width={['100%', '100%', '100%']} mx="auto" p={['2', '4', '4']}>
            <Flex direction='column' alignItems='flex-start'>
              <PricingCard title="Double Pana" price="1 RS KA 300 Rs" fontSize={['sm', 'md', 'xl']} />
              <PricingCard title="Triple Pana" price="1 RS KA 900 Rs" fontSize={['sm', 'md', 'xl']} />
              {/* <PricingCard title="Half Sangam Digits" price="1 RS KA 1000 Rs" fontSize={['sm', 'md', 'xl']} />
              <PricingCard title="Full Sangam Digits" price="1 RS KA 10000 Rs" fontSize={['sm', 'md', 'xl']} /> */}
            </Flex>
          </Box>
        </Flex>
      </Box>
      <LuckyNumber />
      <CardList />
      <Container
        bgGradient="linear(to-r, rgba(182, 214, 255, 0.374), rgba(182, 214, 255, 0.374))"
        p={5}
        maxW="9xl"
        centerContent
      >
        <Center>
          <Heading
            fontSize={['24px', '30px', '40px']}
            mt={['10px', '10px', '20px']}
            mb={['10px', '10px', '50px']}
          >
            Live <Text as="span" ml="2px" className="difText">
              Results
            </Text>
          </Heading>
        </Center>
        <Grid
          templateColumns={['1fr', '1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']}
          gap={4}
        >
          {isLoading ? (
            <div className="loading-spinner">
              <Spinner size="xl" color="red" />
            </div>
          ) : isError ? (
            <p>Error loading data</p>
          ) : (
            tableData.map((item) => (
              <Box key={item.id}>
                <Card item={item} handleDownload={handleDownload} updateCard={updateCard} />
              </Box>
            ))
          )}
        </Grid>
      </Container>
      <ContactCard />
      <ScrollButton />
    </>
  );
}

export default HomePage;
