import React, { useState, useEffect } from 'react';
import { Box, IconButton, useMediaQuery } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';

const ScrollArrows = () => {
  const [showArrows, setShowArrows] = useState(false);
  const [isSmallScreen] = useMediaQuery("(max-width: 768px)");

  // Hook to listen for scroll events
  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const bodyHeight = document.body.clientHeight;
      const scrollPosition = window.scrollY;

      // Adjust this value to control when the arrows appear/disappear
      const scrollThreshold = 100;

      if (scrollPosition > scrollThreshold && scrollPosition < bodyHeight - windowHeight - scrollThreshold) {
        setShowArrows(true);
      } else {
        setShowArrows(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Function to scroll up
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Function to scroll down
  const scrollToBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  };

  return (
    <Box
      position="fixed"
      bottom={isSmallScreen ? "20px" : "50%"}
      right={isSmallScreen ? "20px" : "50px"}
      display={showArrows ? 'block' : 'none'}
      transform={isSmallScreen ? "none" : "translateY(-50%)"}
      zIndex="999" // Ensure arrows are on top of other content
    >
      <IconButton
        icon={<FontAwesomeIcon  bounce  icon={faArrowUp} />}
        onClick={scrollToTop}
        isRound={true}
        aria-label="Scroll to top"
        size="lg" // Adjust the size as needed
        marginTop={'8px'}
        marginRight={'10px'}
        animation="bounce 1s infinite"
      />
      <IconButton
        icon={<FontAwesomeIcon bounce icon={faArrowDown} />}
        onClick={scrollToBottom}
        isRound={true}
        aria-label="Scroll to bottom"
        size="lg" // Adjust the size as needed
        mt="2"
        animation="bounce 1s infinite"
      />
    </Box>
  );
};

export default ScrollArrows;
