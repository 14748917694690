import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Text,
  Alert,
  AlertIcon,
  AlertTitle,
  CloseButton,
  InputGroup,
  InputRightElement,
  IconButton,
  Spinner,
  Heading,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash, FaUser } from "react-icons/fa";
import { jwtDecode } from "jwt-decode";
import { useAuth } from "../AuthContext";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [loadingLogin, setLoadingLogin] = useState(false); // Loading state for regular login
  const [loadingDemo, setLoadingDemo] = useState(false); // Loading state for demo login
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, username: "" }));
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, password: "" }));
  };

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateForm = () => {
    const errors = {};

    if (!username) {
      errors.username = "Username is required";
    } else if (username.length < 3) {
      errors.username = "Username must be at least 3 characters long";
    } else if (username.length > 20) {
      errors.username = "Username cannot exceed 20 characters";
    } else if (!/^[a-zA-Z0-9]+$/.test(username)) {
      errors.username = "Username can only contain letters and numbers";
    }

    if (!password) {
      errors.password = "Password is required";
    } else if (password.length < 8) {
      errors.password = "Password must be at least 8 characters long";
    } else if (password.length > 128) {
      errors.password = "Password cannot exceed 128 characters";
    }

    return errors;
  };

  const handleLoginFormSubmit = async (e) => {
    e.preventDefault();

    const errors = validateForm();

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    setLoadingLogin(true); // Set loading for login button

    try {
      const response = await fetch("https://mainproject-ao3x.onrender.com/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();

      if (response.ok && data.status === "ok") {
        localStorage.setItem("token", data.token);
        const decodedToken = jwtDecode(data.token);
        const { name, username } = decodedToken;

        localStorage.setItem("authenticated", "true");
        localStorage.setItem("user", JSON.stringify({ name, username }));

        login({ name, username });
        showSuccess("Login successful!");
      } else {
        showError(data.error || "Invalid username or password.");
      }
    } catch (error) {
      console.error("Login error:", error);
      showError("An error occurred during login.");
    } finally {
      setLoadingLogin(false); // Reset loading for login button
    }
  };

  const handleDemoLogin = async () => {
    setLoadingDemo(true); // Set loading for demo login button

    // Demo credentials
    const demoUsername = "demo123"; // Use your demo username
    const demoPassword = "demo112233"; // Use your demo password

    try {
      const response = await fetch("https://mainproject-ao3x.onrender.com/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username: demoUsername, password: demoPassword }), // Use demo credentials
      });

      const data = await response.json();

      if (response.ok && data.status === "ok") {
        localStorage.setItem("token", data.token);
        const decodedToken = jwtDecode(data.token);
        const { name, username } = decodedToken;

        localStorage.setItem("authenticated", "true");
        localStorage.setItem("user", JSON.stringify({ name, username }));

        login({ name, username });
        showSuccess("Demo login successful!");
      } else {
        showError(data.error || "Invalid demo username or password.");
      }
    } catch (error) {
      console.error("Login error:", error);
      showError("An error occurred during demo login.");
    } finally {
      setLoadingDemo(false); // Reset loading for demo login button
    }
  };

  const showSuccess = (message) => {
    setAlertMessage(message);
    setShowSuccessAlert(true);
    setTimeout(() => {
      setShowSuccessAlert(false);
      navigate("/");
    }, 300);
  };

  const showError = (message) => {
    setAlertMessage(message);
    setShowErrorAlert(true);
    setTimeout(() => setShowErrorAlert(false), 3000);
  };

  return (
    <Box
      minHeight="100vh"
      bg='black'
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Box mb="4" textAlign="center"> {/* Center the logo on smaller screens */}
        <img
          src="../images/logo-no-background.png"
          alt="Logo"
          style={{
            width: "150px", // Smaller width for mobile
            height: "auto",
            maxWidth: "100%", // Ensure it doesn't overflow
          }}
        />
      </Box>

      <Box
        maxW={{ base: "90%", sm: "400px" }} // Reduce the maximum width for mobile
        mx="auto"
        mt="8"
        p={{ base: "4", sm: "8" }} // Smaller padding for mobile
        minHeight={{ base: "150px", sm: "200px" }} // Adjust minHeight for smaller screens
        mb="40px"
        bg="linear-gradient(135deg, rgba(11, 25, 44, 0.9), rgba(0, 0, 0, 0.8))"
        style={{
          backdropFilter: 'blur(20px)',
          boxShadow: '0 20px 40px rgba(0, 0, 0, 0.3)',
          transition: 'transform 0.3s',
        }}
        borderRadius="md"
        _hover={{ transform: 'translateY(-10px)', boxShadow: '0 25px 50px rgba(0, 0, 0, 0.4)' }}
      >
        <Heading as="h2" size="lg" textAlign="center" mb="4" color="#D2122E">
          Login
        </Heading>
        <form onSubmit={handleLoginFormSubmit}>
          <VStack spacing="4">
            <FormControl isRequired isInvalid={!!errors.username}>
              <FormLabel color="#D2122E">Username</FormLabel>
              <InputGroup>
                <Input
                  type="text"
                  placeholder="Username"
                  value={username}
                  color="white"
                  onChange={handleUsernameChange}
                  size="lg"
                  bg={'black'}
                />
                <InputRightElement width="3rem">
                  <IconButton
                    aria-label="Username icon"
                    icon={<FaUser />}
                    color={'red.600'}
                    variant="ghost"
                  />
                </InputRightElement>
              </InputGroup>
              {errors.username && <Text color="red">{errors.username}</Text>}
            </FormControl>

            <FormControl isRequired isInvalid={!!errors.password}>
              <FormLabel color="#D2122E">Password</FormLabel>
              <InputGroup>
                <Input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  color="white"
                  value={password}
                  onChange={handlePasswordChange}
                  size="lg"
                  bg={'black'}
                />
                <InputRightElement width="3rem">
                  <IconButton
                    aria-label={showPassword ? "Hide password" : "Show password"}
                    onClick={handlePasswordVisibility}
                    icon={showPassword ? <FaEyeSlash /> : <FaEye />}
                    variant="ghost"
                    color={'red.600'}
                  />
                </InputRightElement>
              </InputGroup>
              {errors.password && <Text color="red">{errors.password}</Text>}
            </FormControl>
            <Button
              type="submit"
              color="white"
              fontSize="18px"
              bg="#D2122E"
              w="100%"
              position="relative"
              disabled={loadingLogin}
            >
              {loadingLogin && (
                <Spinner
                  size="sm"
                  color="white"
                  position="absolute"
                  left="50%"
                  top="50%"
                  transform="translate(-50%, -50%)"
                />
              )}
              Login
            </Button>

            <Button
              color="white"
              fontSize="18px"
              bg="#D2122E"
              w="100%"
              onClick={handleDemoLogin}
              disabled={loadingDemo} // Disable button if demo login is in progress
            >
              {loadingDemo && (
                <Spinner
                  size="sm"
                  color="white"
                  position="absolute"
                  left="50%"
                  top="50%"
                  transform="translate(-50%, -50%)"
                />
              )}
              Login With Demo ID
            </Button>
          </VStack>
        </form>
        {/* Success Alert */}
        {showSuccessAlert && (
          <Alert status="success" mb="1">
            <AlertIcon />
            <AlertTitle>{alertMessage}</AlertTitle>
            <CloseButton onClick={() => setShowSuccessAlert(false)} />
          </Alert>
        )}

        {/* Error Alert */}
        {showErrorAlert && (
          <Alert status="error" mb="4">
            <AlertIcon />
            <AlertTitle mr={2}>{alertMessage}</AlertTitle>
            <CloseButton onClick={() => setShowErrorAlert(false)} />
          </Alert>
        )}
      </Box>
    </Box>
  );
};

export default Login;
