import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import {
  Box,
  Center,
  Heading,
  Button,
  Text,
  Table,
  Thead,
  Tbody,
  VStack,
  HStack,
  Tr,
  Th,
  Td,
  color,
  border,
} from '@chakra-ui/react';
import ContentLoader from 'react-content-loader';
import ScrollArrows from '../Slidebutton';
import '../Style/RecordsTable.css';

import { useNavigate } from 'react-router-dom';
const tableStyles = {
  width: '100%',
  borderCollapse: 'collapse',
  marginTop: '20px',
  
};

const thStyles = {
  backgroundColor: 'black',
  fontWeight: 'bold',
  padding: '8px',
  color:"white",
  border:"1px solid grey",
  textAlign: 'center',
};

// const tdStyles = {
//   padding: '8px',
//   textAlign: 'center',
//   borderBottom: '1px solid #e0e0e0',
// };

const buttonStyles = {
  background: 'linear-gradient(90deg, #d2122e, black)',
  color: '#fff',
  padding: '10px 20px',
  borderRadius: '5px',
  cursor: 'pointer',
  marginRight: '10px',
  whiteSpace: 'nowrap', // Prevent button text from wrapping
  overflow: 'hidden', // Hide overflow text
  textOverflow: 'ellipsis', // Add ellipsis for long text
  // Responsive Styles
  '@media (max-width: 768px)': {
    padding: '8px 16px',  // Reduced padding for tablets
  },
  '@media (max-width: 480px)': {
    padding: '6px 12px',  // Further reduced padding for mobile devices
  },
};

const containerStyles = {
  padding: '20px',
  backgroundColor: '#fff',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  borderRadius: '5px',
};

// const initialLoadingState = {
//   isLoading: true,
//   isDataLoaded: false,
// };

function PanelChartTable() {
  const { id } = useParams();
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState({ isLoading: true, isDataLoaded: false });
  const [tableIndex] = useState(0);
  const [visibleRows, setVisibleRows] = useState(10); // Number of rows initially visible
  const [isFetching, setIsFetching] = useState(false);
  const navigate = useNavigate(); // Get the navigate function from react-router-dom

  const fetchData = async () => {
    try {
      const response = await axios.get(`https://mainproject-ao3x.onrender.com/customchart/${id}`);
      const fetchedRecords = response.data;

      // Assuming 'startDate' is present in each record
      const sortedRecords = fetchedRecords.sort((a, b) => {
        const dateA = new Date(a.startDate.replace(/(\d+)-(\w+)-(\d+)/, '$2 $1, $3'));
        const dateB = new Date(b.startDate.replace(/(\d+)-(\w+)-(\d+)/, '$2 $1, $3'));
        return dateA - dateB;
      });

      // console.log('Sorted Records:', sortedRecords);
      setRecords(sortedRecords);
      setLoading({ isLoading: false, isDataLoaded: true });
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading({ isLoading: false, isDataLoaded: false });
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, tableIndex]);

  // const fetchMoreData = () => {
  //   if (currentPage < totalPages) {
  //     setCurrentPage((prev) => prev + 1);
  //   }
  // };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
      document.documentElement.offsetHeight ||
      isFetching
    ) {
      return;
    }
    setIsFetching(true);
  };

  useEffect(() => {
    if (!isFetching) return;
    if (visibleRows >= records.length) {
      setIsFetching(false);
      return;
    }
    setVisibleRows((prevVisibleRows) => prevVisibleRows + 20); // Adjust the number of rows to load here
    setIsFetching(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  };

  function determineTextColor(day, field1, field2) {
    const specialNumbersPattern = /(11|22|33|44|55|66|77|88|99|00|11|16|22|27|33|38|44|49|50|66|61|72|83|94|05)/;
    const isSpecialNumber = specialNumbersPattern.test(day);
    return isSpecialNumber ? 'red' : 'inherit';
  }

  const memoizedRecords = useMemo(() => records, [records]);

  const getAvailableDays = () => {
    const days = new Set();
    records.forEach((record) => {
      Object.keys(record.daysData).forEach((day) => {
        if (record.daysData[day].field1 || record.daysData[day].field2) {
          days.add(day);
        }
      });
    });
    return Array.from(days);
  };

  const availableDays = getAvailableDays();

  const TableRow = useCallback(
    ({ record }) => (
      <Tr>
        <Td  border={'1px solid black'} bg={'yellow.100'}>
          <div className="date-range2" >
            <Text>{record.startDate}</Text>
            <Text className="date-separator">to</Text>
            <Text>{record.endDate}</Text>
          </div>
        </Td>
        {availableDays.map((day, index) => (
          <Td key={index} bg="yellow.100"  border={'1px solid black'}> {/* Adding background color to the table cell */}
            <HStack justify="center" spacing="8px">
              {/* Displaying field1 in a vertical stack, non-bold and smaller size */}
              <VStack spacing="0" align="center">
                {record.daysData?.[day]?.field1?.split("").map((num, i) => (
                  <Text
                    key={`field1-${i}`}
                    fontWeight="normal"
                    textAlign="center"
                    fontSize="lg"
                    color={determineTextColor(
                      record.daysData?.[day]?.day,
                      record.daysData?.[day]?.field1,
                      record.daysData?.[day]?.field2
                    )}
                  >
                    {num}
                  </Text>
                ))}
              </VStack>


              <VStack spacing="0" align="center">
                <Text
                  fontWeight="bold"
                  textAlign="center"
                  fontSize="xl"
                  color={determineTextColor(
                    record.daysData?.[day]?.day,
                    record.daysData?.[day]?.field1,
                    record.daysData?.[day]?.field2
                  )}
                >
                  {record.daysData?.[day]?.day}
                </Text>
              </VStack>


              <VStack spacing="0" align="center">
                {record.daysData?.[day]?.field2?.split("").map((num, i) => (
                  <Text
                    key={`field2-${i}`}
                    fontWeight="normal"
                    textAlign="center"
                    fontSize="lg"
                    color={determineTextColor(
                      record.daysData?.[day]?.day,
                      record.daysData?.[day]?.field1,
                      record.daysData?.[day]?.field2
                    )}
                  >
                    {num}
                  </Text>
                ))}
              </VStack>
            </HStack>
          </Td>
        ))}


      </Tr>
    ),
    [availableDays]
  );

  return (
    <>
      <Box className="records-table-container" w="100%" maxW="1150px" p={5} style={containerStyles}>
      <Center>
        <Heading 
          as="h2" 
          size={["md", "md", "xl", "2xl"]}  // Responsive sizes for mobile screens
          mb={3}
        >
          {id}
        </Heading>
      </Center>
      <Center>
        <Text 
          fontSize={["md", "md", "xl", "2xl"]}  // Responsive sizes for mobile screens
          color="gray.600"
        >
          Here, you can see the {id} Panel daily chart.
        </Text>
      </Center>
        <Center>
          <Button
            onClick={scrollToBottom}
            mt={2}
            ml={2}
            style={buttonStyles}
            sx={{
              // Styles for screens less than or equal to 636px (mobile)
              '@media (max-width: 320px)': {
                fontSize: '13px',
                fontWeight: 'bold',
                w: '60%',
              },
            }}
          >
            Go to Bottom&nbsp;
            <FontAwesomeIcon bounce icon={faArrowDown} />
          </Button>
        </Center>
        <div className="scrollable-content" style={{ overflowX: 'auto' }}>
          {loading.isLoading ? (
            <ContentLoader
              speed={2}
              width="100%"
              height={200}
              viewBox="0 0 400 200"
              backgroundColor="grey"
              foregroundColor="#ecebeb"
              style={{ marginTop: '55px' }}
            >

              {Array.from({ length: 4 }).map((_, index) => (
                <rect key={index} x="0" y={index * 40} rx="4" ry="4" width="100%" height="20" />
              ))}

            </ContentLoader>
          ) : loading.isDataLoaded ? (
            <Table style={tableStyles} border={'1px solid black'}>
              <Thead>
                <Tr>
                  <Th style={thStyles}>Date Range</Th>
                  {availableDays.map((day, index) => (
                    <Th key={index} style={thStyles}>
                      {day}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {memoizedRecords.map((record, index) => (
                  <TableRow key={index} record={record} />
                ))}
              </Tbody>
            </Table>
          ) : (
            <div>Data failed to load.</div>
          )}
          <Center>
            <Button
              onClick={() => navigate(-1)} // Go back to the previous path
              mt={2}
              ml={2}
              style={buttonStyles}
              sx={{
                // Styles for screens less than or equal to 636px (mobile)
                '@media (max-width: 320px)': {
                  fontSize: '13px',
                  fontWeight: 'bold',
                  w: '60%',
                },
              }}
            >
              Back
            </Button>
            <Button
              onClick={scrollToTop}
              mt={2}
              ml={2}
              style={buttonStyles}
              sx={{
                // Styles for screens less than or equal to 636px (mobile)
                '@media (max-width: 320px)': {
                  fontSize: '13px',
                  fontWeight: 'bold',
                  w: '60%',
                },
              }}
            >
              Go to Top &nbsp;
              <FontAwesomeIcon bounce icon={faArrowUp} />
            </Button>
          </Center>
        </div>
      </Box>
      <ScrollArrows />
    </>
  );
}

export default PanelChartTable;





